import { EightK } from "@mui/icons-material";
import { get, post } from "../index.js";
import { ContactRoutes } from "./contact.Routes.js";

export const ContactServices = {
  Contactinformation: async (Data) => {
    const result = await post(ContactRoutes.Contactinfo, Data);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
