import React, { Fragment, useState } from "react";
import { Box, Grid, CardMedia, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigationBar from "app/components/navigation/navigationBar";
import Footer from "app/components/footer/footer";

function Preview() {
  let productsInCart = useSelector(
    (state) => state.cartDetailReducer?.cartValue
  );
  let cartData = productsInCart.flatMap((arr) => arr);
  let designData = useSelector((state) => state.DesignReducer?.DesignData);
  let textData = useSelector((state) => state.DesignReducer?.TextData);
  let allData = useSelector((state) => state.DesignReducer?.AllData);
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  // const arrOfObj = data.flatMap((arr) => arr)

  const [image, setImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [border, setBorder] = useState(null);

  console.log("cartData", cartData);
  console.log("designData", designData);
  console.log("textData", textData);
  console.log("allData", allData);
  console.log("data", data);

  const handleImageClick = (previewImage, index) => {
    setImage(previewImage);
    setCurrentIndex(index);
    setBorder(index);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };

  return (
    <Fragment>
      <Grid container padding={"20px"}>
        <Grid item md="12">
          <Grid container justifyContent={"center"}>
            <Grid item md="5" sm="12" xs="12">
              <Box
                sx={{
                  borderRadius: 2,
                  padding: "20px",
                  boxShadow: "1px 1px 10px 1px lightgray",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {/* Box Header */}
                <Grid container>
                  <Grid
                    item
                    xs="12"
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      component={"div"}
                      onClick={handleBack}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <ArrowBackIcon />
                    </Box>
                    <Box>
                      <Typography variant="h6">Design Preview</Typography>
                    </Box>
                    <Box>
                      <Button
                        onClick={handleCheckout}
                        sx={{
                          padding: "5px 10px !important",
                          background: "black",
                          color: "white",
                          fontSize: "14px",
                          border: "1px solid black",
                          ":hover": {
                            background: "white",
                            color: "black",
                            border: "1px solid black",
                          },
                        }}
                      >
                        Checkout
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                {/* Image and Design Section */}
                <Grid container>
                  <Grid
                    item
                    xs="12"
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      order: { xs: 1, sm: 1, md: 2 },
                      py: 1,
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "fit-content",
                        height: "fit-content",
                      }}
                    >
                      <CardMedia
                        component={"img"}
                        image={data?.Product[currentIndex]?.image}
                        sx={{
                          // width: "100%",
                          // height: "100%",
                          width: "400px",
                          height: "500px",
                          objectFit: "contain",
                          // position: "relative",
                          backgroundColor: data?.state?.colorCode,
                        }}
                      />
                      <Box
                        // position={"absolute"}
                        sx={{
                          position: "absolute",
                          top: `${data?.Product[currentIndex]?.top}%`,
                          left: `${data?.Product[currentIndex]?.left}%`,
                          width: `${data?.Product[currentIndex]?.width}%`,
                          height: `${data?.Product[currentIndex]?.height}%`,
                          zIndex: "1",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          position={"relative"}
                          sx={{
                            width: "100%",
                            height: "100%",
                            zIndex: "999",
                            // top: designData[currentIndex]
                            //   ? designData[currentIndex]?.yaxis
                            //   : textData[currentIndex]?.yaxis,
                            // left: designData[currentIndex]
                            //   ? designData[currentIndex]?.xaxis
                            //   : textData[currentIndex]?.xaxis,
                          }}
                        >
                          {data.imageData[currentIndex] != null && (
                          <CardMedia
                            component={"img"}
                            image={data.imageData[currentIndex]}
                            sx={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              // position: "absolute",
                              // top: designData[currentIndex]
                              //   ? designData[currentIndex]?.yaxis
                              //   : textData[currentIndex]?.yaxis,
                              // left: designData[currentIndex]
                              //   ? designData[currentIndex]?.xaxis
                              //   : textData[currentIndex]?.xaxis,
                            }}
                          />
                        )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/* All Images Section */}
                <Grid container>
                  <Grid item xs="12">
                    <Grid container justifyContent={"center"} gap={"20px"}>
                      {data.Product.map((item, index) => (
                        <Grid item xs="2" key={index}>
                          <Box
                            component={"div"}
                            sx={{
                              padding: "10px",
                              border:
                                border === index
                                  ? "1px solid #000000"
                                  : "1px solid transparent",
                              borderRadius: "10px",
                              transition: "border 0.2s ease-in-out",
                            }}
                          >
                            <CardMedia
                              component={"img"}
                              alt={item.img_name}
                              image={item.image}
                              height={"100%"}
                              onClick={() => {
                                handleImageClick(item.image, index);
                              }}
                              sx={{
                                cursor: "pointer",
                                backgroundColor: data?.state?.colorCode,
                              }}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Preview;
