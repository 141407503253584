import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { emailRegex } from 'app/utils';
import { ContactServices } from 'app/apis/Contact/contact.Services';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/toaster';
import { useNavigate } from 'react-router-dom';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    comment: ''
  });
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset 
  } = useForm();

  // const handleChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value
  //   });
  // };

  let navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    
    try {
      const result = await ContactServices.Contactinformation(data);
      console.log('API response:', result);
      if (result.responseCode === 200) {
      setLoading(false);
      SuccessToaster("Contact Details Saved Successfully");
      reset(); 
    } else {
      ErrorToaster("Oops Error Occur!");
    }
    } catch (error) {
      ErrorToaster(error);
    }finally {
    setLoading(false);
    }
  };
  
  return (
    <Box sx={{ flexGrow: 1, mt: 8 }}>

      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={5} >
          <Typography component="h2" variant="h4" align="center" gutterBottom>
            Contact Us
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  label="Name"
                  // name="name"
                  // value={formData.name}
                  // onChange={handleChange}
                  {...register("name", { required: "Name is required" })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  error={errors?.email?.message}
                  // name="email"
                  // value={formData.email}
                  // onChange={handleChange}
                  required
                  // id="email"
                  helperText={errors?.email?.message}
                    // autoComplete="email"
                    {...register("email", {
                      required: "Enter email please*",
                      pattern: {
                        value: emailRegex,
                        message: "Enter valid Email ",
                      },
                    })}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField
                  fullWidth
                  label="Phone Number"
                  // name="phone"
                  // value={formData.phone}
                  // onChange={handleChange}
                  {...register("phone")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  label="Comment"
                  // name="comment"
                  // value={formData.comment}
                  // onChange={handleChange}
                  error={errors?.comment?.message}
                  helperText={errors?.comment?.message}
                  {...register("comment", {
                    required: "Enter comment please*",
                  })}
                />
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ 
                mt: 3,
                  mb: 2,
                  background: "black",
                  ":hover": {
                    background: "white",
                    color: "black",
                    boxShadow:
                      " rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
                  },
               }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactUs;
