import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Footer() {

  let navigate = useNavigate();

  return (
    <Box sx={{ bgcolor: 'black', pb: 1, pt: 3 }}>
      <Container>
        <Grid container spacing={0} alignItems="center" justifyContent="center" >
          <Grid container spacing={1} item xs={12} md={8} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={3}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {/* Hevi embroidery */}
                Brooklyn made Kings
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/about");
                }}
              >
                About us
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={3}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Contact us
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Terms & Conditions
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Privacy Policy
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={3}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/return-policy");
                }}
              >
                Return Policy
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          sx={{
            color: '#fff',
            py: 2,
            textAlign: 'center',
            display: 'block',
          }}
        >
          {/* © 2024 Hevi, All Rights Reserved. */}
          © 2024 Brooklyn Made Kings, All Rights Reserved.
          {/* <span
          style={{
            color: '#fff',
            my: 1,
            // textAlign: 'center',
            // display: 'block',
            marginLeft: "10px",
            transition: "2s ease-in-out",
            cursor: "pointer",
            // textTransform: 'uppercase',
            ":hover": { textDecoration: "underline" },
          }}
           onClick={() => {
            navigate("/terms-and-conditions");
          }}
          >Terms & Conditions</span>
          <span
          style={{
            color: '#fff',
            my: 1,
            marginLeft: "10px",
            // textAlign: 'center',
            // display: 'block',
            transition: "2s ease-in-out",
            cursor: "pointer",
            // textTransform: 'uppercase',
            ":hover": { textDecoration: "underline" },
          }}
           onClick={() => {
            navigate("/terms-and-conditions");
          }}
          >Privacy Policy</span> */}
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
