import Footer from "app/components/footer/footer";
import NavigationBar from "app/components/navigation/navigationBar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

function WebLayout() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <NavigationBar />
      <Box component={"main"} sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}

export default WebLayout;