import React from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { publicRoute } from "./routes/publicroute";
import { privateRoute } from "./routes/privateRoute";
import { Toaster } from "./components/Toaster/toaster";
import useAuth from "./context/useContext/useContext";
import WebLayout from "./layout";

function App() {
  let token = localStorage.getItem('jwt')
  let { user } = useAuth()
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>
        <Route element={<WebLayout />}>
          {publicRoute.map((items, index) => (
            <Route key={index} path={items.path} element={items.component} />
          ))}


          <Route
            element={!user || !token && <Navigate to="/login" />}
          >
            {privateRoute.map((items, index) => {
              return (
                <Route key={index} path={items.path} element={items.component} />
              );
            })}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
