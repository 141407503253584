import React from 'react'
import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import Footer from 'app/components/footer/footer'
import NavigationBar from 'app/components/navigation/navigationBar'
import Images from 'app/assets/images'

function TermsAndCondition() {
  return (
    <>
      <Box sx={{ mt: 4, mb: 6 }}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8} lg={6}>
            <Typography variant="h3" align="center">
              Terms and Conditions
            </Typography>

            <Typography variant="body1" align="center" sx={{ mt: 2 }}>
            LAST UPDATED: JUNE, 2024
            </Typography>
            
            <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
            By using this platform you declare that you own or have the
            rights to use the images that you upload or save on our platform
            Users are solely responsible for their uploaded and save images
            </Typography>
            
            <Typography variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS IS OWNED AND OPERATED BY RENTSTARZ LLC.
            BY VISITING, USING AND/OR REGISTERING WITH BROOKLYN MADE
            KINGS ("SITE"), YOU (REFERRED TO HEREAFTER AS "YOU", "YOUR" OR
            "USER") AGREE TO BE BOUND BY THE FOLLOWING TERMS AND
            CONDITIONS ("TERMS & CONDITIONS" or “TERMS”). PLEASE READ AND
            CAREFULLY REVIEW THESE TERMS & CONDITIONS, AS THEY
            CONSTITUTE A LEGALLY BINDING AGREEMENT BETWEEN YOU AND
            RENTSTARZ LLC (TOGETHER WITH ITS AFFILIATES HEREAFTER ALSO
            REFERRED TO BY THE BRAND NAME OF THIS SITE, “BROOKLYN MADE
            KINGS” AS WELL AS "WE", "US", OR "OUR") AND GOVERN ANY AND ALL
            USE OF THE SITE BY ANY AND ALL USERS OF THE SITE AND ANY
            SERVICES OFFERED THEREFROM.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
            YOU SHOULD PAY ATTENTION TO THE ARBITRATION PROVISION SET
            FORTH BELOW WHICH, EXCEPT AND TO THE EXTENT PROHIBITED BY
            LAW, REQUIRES YOU TO ARBITRATE ANY CLAIMS YOU MAY HAVE
            AGAINST RENTSTARZ LLC. OR ITS AFFILIATES ON AN INDIVIDUAL BASIS,
            RATHER THAN RESOLVING DISPUTES BY JURY TRIALS OR CLASS
            ACTIONS.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            IF YOU DO NOT AGREE TO ALL OF THE TERMS & CONDITIONS, PLEASE
          DO NOT USE THE SITE OR ANY SERVICES OFFERED OR ACCESSED
          THROUGH THE SITE. IF YOU (OR THE BUSINESS ENTITY WHOM YOU
          REPRESENT OR ARE ACTING ON BEHALF) HAVE A SEPARATE
          AGREEMENT WITH, BROOKLYN MADE KINGS, THE TERMS OF THAT
          SEPARATE AGREEMENT SHALL GOVERN TO THE EXTENT THEY ARE
          INCONSISTENT WITH OR OTHERWISE CONFLICT WITH ANY OF THE
          TERMS & CONDITIONS.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Copyright Notice
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            You acknowledge that all content included on this Site, including,
            without limitation, the information, data, software, photographs,
            graphs, typefaces, graphics, images, illustrations, maps, designs, icons,
            written and other material and compilations (collectively, "Content")
            are intellectual property and copyrighted works of BROOKLYN MADE
            KINGS, its licensees, and/or various third-party providers ("Providers").
            Reproductions or storage of Content retrieved from this Site, in all
            forms, media and technologies now existing or hereafter developed, is
            subject to the U.S. Copyright Act of 1976, Title 17 of the United States
            Code. Except where expressly provided otherwise by us, nothing made
            available to users via the Site may be construed to confer any license or
            ownership right in or materials published or otherwise made available
            through the Site or its services, whether by estoppel, implication, or
            otherwise. All rights not granted to you in the Terms & Conditions are
            expressly reserved by us.
            </Typography>
            
            <Typography variant="h5" sx={{ mt: 2 }}>
             Corporate Identification and Trademarks
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            "BROOKLYN MADE KINGS" and any and all other marks appearing on
            this Site are trademarks of Rentstarz, LLC. in the United States and other
            jurisdictions ("Trademarks"). You may not use, copy, reproduce,
            republish, upload, post, transmit distribute or modify the Trademarks in
            any way, including in advertising or publicity pertaining to distribution
            of materials on this Site, without Brooklyn Made Kings prior written
            consent. The use of Trademarks on any other website or network
            computer environment is prohibited. Rentstarz, LLC. prohibits the use of
            Trademarks as a "hot" link on, or to, any other website unless
            establishment of such a link is pre-approved by Rentstarz, LLC. in
            writing.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              User Conduct & Eligibility
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            You are solely responsible for the content and context of any materials
            you post or submit through the Site. You warrant and agree that while
            using the Site, you shall not upload, post, transmit, distribute or
            otherwise publish through the Site any materials which: (a) are
            unlawful, threatening, harassing or profane; (b) restrict or inhibit any
            other user from using or enjoying the Site; (c) constitute or encourage
            conduct that would constitute a criminal offense or give rise to civil
            liability; or (d) contain a virus or other harmful component or false or
            misleading indications or origin or statements of fact.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            As a condition of your use of certain services offered through the Site,
            you may be required to register an account with us and must provide
            true and accurate account information at all times (including, without
            limitation, ensuring that your account information remains current at all
            times.) You agree to promptly update your membership information (if
            applicable) in order to keep it current, complete and accurate.
            </Typography>

            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            <u>Account Security:</u>You are solely responsible for protecting the
            confidentiality of your password and may not disclose your password to
            any other person. In the event that an unauthorized user gains access to
            the password-protected area of the Site as a result of your acts or
            omissions, you agree that you shall be liable for any such unauthorized
            use.
            </para>

              <br />
            <para variant="body1" sx={{ mt: 2 }}>
            <u>Minimum Age. </u>The Site and its services are intended solely for persons
            who are 18 years of age or older. Any access to or use of the Site or its
            service by anyone under 18 years of age is expressly prohibited. By
            accessing or using the Site, you represent and warrant that you are 18
            years old or older.
            </para>
            <Typography variant="h5" sx={{ mt: 2 }}>
             User Restrictions
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            You may only use this Site to make legitimate requests to purchase the
            products or services offered (each, a "Request"), and shall not use this
            Site to make any speculative, false or fraudulent Requests. You may not
            use robots or other automated means to access this Site, unless
            specifically permitted by Rentstarz LLC. You represent that you are of
            sufficient legal age to create binding legal obligations for any liability
            you may incur as a result of your use of this Site.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            It is a violation of law to place a Request in a false name or with an
            invalid credit card. Please be aware that even if you do not give us your
            real name, your Web browser transmits a unique Internet address to us
            that can be used by law enforcement officials to identify you.
            Fraudulent users may be prosecuted to the fullest extent of the law.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Permission is granted to electronically copy and print in hard-copy
            portions of this Site for the sole purpose of using this Site as a shopping
            resource. Any other use of materials or Content on this Site, including
            reproduction for a purpose other than that noted above without
            Rentstarz LLC prior written consent is prohibited.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            In addition to the foregoing and in consideration of being allowed to
            use the Site, you agree that the following actions shall constitute a
            material breach of the Terms & Conditions:
            </Typography>
            <List component="ol" style={{ listStyleType: 'disc' }}>
                <ListItem>
                <ListItemText primary="Collecting information about the Site or users of the Site without our written consent;" />
                </ListItem>
                <ListItem>
                <ListItemText primary="Modifying, framing, rendering (or re-rendering), mirroring,
                truncating, injecting, filtering or changing any content or
                information contained in the Site, without our written consent. " />
                </ListItem>
                <ListItem>
                <ListItemText primary="Using any deep-link, page-scrape, robot, crawl, index, spider, click
                spam, macro programs, Internet agent, or other automatic device,
                program, algorithm or methodology which does the same things,
                to use, access, copy, acquire information, generate impressions or
                clicks, input information, store information, search, generate
                searches, or monitor the Site or any portion thereof; " />
                </ListItem>
                <ListItem>
                <ListItemText primary="Accessing or using the Site for competitive purposes;" />
                </ListItem>
                <ListItem>
                <ListItemText primary="DisgDisguising the origin of information transmitted to, from, or through the Site. " />
                </ListItem>
                <ListItem>
                <ListItemText primary="Impersonating another person; " />
                </ListItem>
                <ListItem>
                <ListItemText primary="Distributing viruses or other harmful computer code;" />
                </ListItem>
                <ListItem>
                <ListItemText primary="Allowing any other person or entity to impersonate you to access or use the Site; " />
                </ListItem>
                <ListItem>
                <ListItemText primary="Using the Site for any purpose in violation of local, state, national, international laws " />
                </ListItem>
                <ListItem>
                <ListItemText primary="Using the Site in a way that is intended to harm, or a reasonable
                person would understand would likely result in harm, to the user
                or others " />
                </ListItem>
                <ListItem>
                <ListItemText primary="Circumventing any measures implemented by us aimed at preventing violations of the Terms & Conditions. " />
                </ListItem>
            </List>

            <Typography variant="body1" sx={{ mt: 2 }}>
            We expressly reserve the right, in our sole discretion, to terminate a
            User's access to any or all areas of the Site due to any act that would
            constitute a violation of these Terms & Conditions.
            </Typography>


            <Typography variant="h5" sx={{ mt: 2 }}>
            Linking to the Site
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Creating or maintaining any link from another website to any page on
            this Site without our prior written permission is prohibited. Running or
            displaying this Site or any material or content displayed on this Site in
            frames or through similar means on another website without our prior
            written permission is prohibited. Any permitted links to this Site must
            comply will all applicable laws, rule and regulations.
            </Typography>
            <Typography variant="h5" sx={{ mt: 2 }}>
            Copyright
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            The Digital Millennium Copyright Act of 1998 (the "DMCA") provides
            recourse for copyright owners who believe that material appearing on
            the Internet infringes their rights under U.S. Copyright law. If you
            believe in good faith that materials appearing on this Site infringe your
            copyright, you (or your agent) may send us a notice requesting that the
            material be removed, or access to it blocked. A conforming notice must
            contain the following: (a) your name, address, telephone number, and
            email address (if any); (b) identification of the material that is claimed
            to be infringing or to be the subject of infringing activity and that is to
            be removed or access to which is to be disabled, and information
            reasonably sufficient to locate the material; (c) statement that you, the
            complaining party, has a good faith belief that use of the material in the
            manner complained of is not authorized by the copyright owner, its
            agent, or the law; (d) a statement under penalty of perjury that the
            information in the notification is accurate and that you are (or are
            authorized to act on behalf of) the owner of an exclusive right that is
            allegedly infringed; and (d) your physical or electronic signature as the
            owner or a person authorized to act on behalf of the owner of an
            exclusive right that is allegedly infringed.
            </Typography>
              <br />
            <para variant="body1" sx={{ mt: 2 }}>
            You can find more information is the U.S. Copyright Office website,
          currently located at <a href="http://www.loc.gov/copyright">http://www.loc.gov/copyright</a>. In accordance with
          the DMCA, BROOKLYN MADE KINGS. has designated an agent to receive
          notification of alleged copyright infringement in accordance with the
          DMCA:
            </para>

            <Typography variant="body1" sx={{ mt: 2 }}>
                 E-mail Address: <a href="mailto:SALES@BrooklynMadeKings.com">SALES@BrooklynMadeKings.com</a>
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
                Optional Tools
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            We may provide you with access to third-party tools which we are
            under no obligation to control, monitor, correct or to provide, make
            available, support or ensure the continued availability of such thirdparty tools. You acknowledge and agree that we provide access to such
            tools on an “as is” and “as available” basis without warranty of any kind
            and without any endorsement. We shall have no liability whatsoever
            arising from or relating to your use of optional third-party tools. The
            third-party service provider is solely responsible for the performance of
            its third-party tools, as well as for any claims or warranties in relation to
            it.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Any use by you of optional tools offered through the site is entirely at
            your own risk and discretion and you should ensure that you are
            familiar with and approve of the terms on which tools are provided by
            the relevant third-party provider(s).
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            We may also, in the future, offer new services and/or features through
            the website (including, the release of new tools and resources). Such
            new features and/or services shall also be subject to these Terms &
            Conditions.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Links to Third-Party Websites
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            From time to time, this Site may contain links to websites that are not
            owned, operated or controlled by us or our affiliates. All such links are
            provided solely as a convenience to you. If you use these links, you will
            leave this Site. Neither we nor any of our affiliates are responsible for
            any content, materials or other information located on or accessible
            from any other website. Neither we nor any of our affiliates endorse,
            guarantee, or make any representations or warranties regarding any
            other website, or any content, materials or other information located or
            accessible from such websites, or the results that you may obtain from
            using such websites. If you decide to access any other website linked to
            or from this website, you do so entirely at your own risk.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
              Privacy
            </Typography>
            <br />
            <para variant="body1" sx={{ mt: 2 }}>
              You confirm that you have read, understood and agree to the 
              <b> BROOKLYN MADE KINGS</b> <i style={{ color: 'grey' }}>Privacy_Policy</i>, the terms of which are
              incorporated herein, and agree that the terms of such policy are
              reasonable and satisfactory to you. You consent to the use of your
              personal information by BROOKLYN MADE KINGS, its affiliates, third-party
              providers, and/or distributors in accordance with the terms of and
              for the purposes set forth in the BROOKLYN MADE KINGS <i style={{ color: 'grey' }}>Privacy Policy</i>.
              If you are not a resident of the United States, please note that the
              personal information you submit to the Site will be transferred to the
              United States and processed by BROOKLYN MADE KINGS in order to
              provide this Site and its services to you, or for such other purposes (as
              set forth in the <i style={{ color: 'grey' }}>Privacy Policy</i>).
            </para>

            <Typography variant="h5" sx={{ mt: 2 }}>
            DISCLAIMER OF WARRANTIES
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            THE SITE, INCLUDING ALL CONTENT, FUNCTIONS, AND INFORMATION
          MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, IS PROVIDED
          ON AN "AS IS" "AS AVAILABLE" BASIS WITHOUT REPRESENTATIONS OR
          WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED,
          INCLUDING WITHOUT LIMITATION NON-INFRINGEMENT,
          MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.
          BROOKLYN MADE KINGS. DOES NOT WARRANT THAT THE SITE OR THE
          FUNCTIONS, FEATURES OR CONTENT CONTAINED THEREIN WILL BE
          TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS
          WILL BE CORRECTED. SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OF CERTAIN WARRANTIES, SO SOME OF THE ABOVE
          EXCLUSIONS MAY NOT APPLY TO CERTAIN USERS.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            LIMITATION OF LIABILITY
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
          RENTSTARZ LLC.] - INCLUDING ITS OFFICERS, DIRECTORS, EMPLOYEES,
          REPRESENTATIVES, SUCCESSORS, ASSIGNS OR AFFILIATES
          (COLLECTIVELY, THE "COVERED PARTIES") - BE LIABLE FOR ANY INJURY,
          DEATH, LOSS, CLAIM, DAMAGE, ACT OF GOD, ACCIDENT, DELAY, OR ANY
          SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
          DAMAGES OF ANY KIND, WHETHER BASED IN CONTRACT, TORT OR
          OTHERWISE, WHICH ARISE OUT OF OR ARE IN ANY WAY CONNECTED
          WITH ANY USE OF THIS SITE OR PRODUCTS OR SERVICES SOLD
          THEREON OR FAILURE TO PROVIDE PRODUCTS OR SERVICES THAT YOU
          ORDERED FROM BROOKLYN MADE KINGS, WITH ANY DELAY OR
          INABILITY TO USE THIS SITE, OR FOR ANY INFORMATION, SOFTWARE,
          PRODUCTS OR SERVICES OBTAINED THROUGH THIS SITE, EVEN IF A
          PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF LIABILITY
          FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE
          ABOVE EXCLUSIONS MAY NOT APPLY TO CERTAIN USERS. IN NO EVENT
          SHALL THE COVERED PARTIES' TOTAL LIABILITY TO YOU FOR ALL
          DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER IN CONTRACT
          OR TORT, INCLUDING BUT NOT LIMITED TO NEGLIGENCE) ARISING
          FROM THIS AGREEMENT OR YOUR USE OF THE SITE EXCEED THE
          AMOUNT PAID BY YOU TO BROOKLYN MADE KINGS FOR THE REQUEST.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Indemnification
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            You agree to defend, indemnify and hold harmless the Covered Parties
            (as defined above) from and against any and all claims, damages, costs
            and expenses, including attorneys' fees, arising from or related to (A)
            your use of the Site, (B) your breach of the Terms & Conditions, (C) your
            dispute with another user, (D) the unauthorized access to any
            password-protected area of the Site using your password, (E) any image
            or content being reproduced as part of your order; (F) any alleged or
            actual infringement, misappropriation or violation of intellectual
            property rights by any image, content or other materials provided by
            You in connection with the Site, and (G) circumstances where user uses
            copy, photographs, or illustrations that are believed by others to be
            degrading, libelous or harmful to their reputations, images, or standing
            in the community or which in RENTSTARZ LLC sole judgment is an
            infringement on a trademark, or trade name, or service mark, or
            copyright belonging to others, or in a suit or court action brought
            against for actions of the user or user's employees which may occur as a
            result of any mailing service including mailing list rentals. If using the
            Site on behalf of a Third Party (as described below), you agree to
            defend, indemnify and hold harmless the Covered Parties as described
            on behalf of yourself and any Third Party.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Third Parties
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            If you use this Site to submit Requests for or on behalf of a third party
            ("Third Party"), you are responsible for any error in the accuracy of
            information provided in connection with such use as well as for any
            Request submitted, including related fees, charges and performance
            obligations. In addition, you must inform the Third Party of all Terms &
            Conditions applicable to all products or services acquired through this
            Site, including all rules and restrictions applicable thereto. RENTSTARZ
            LLC may sublicense the rights that you grant it in this Section to a thirdparty subcontractor only for purposes of operating the Site, providing
            the product and services, processing your order, and producing and
            shipping your products.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            User Social Media Content License
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            RENTSTARZ LLC may reach out to social media users to seek their
            permission to feature our favorite social media content including
            photos, text, graphics, audio, video, location information, comments
            and other materials from such social media sites (“User Social Media
            Content”) on our Site and promotional materials. If you choose to allow
            us to use your User Social Media Content, you agree to the following
            User content license:

            </Typography>
            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            You hereby grant to RENTSTARZ LLC a worldwide, perpetual,
            irrevocable, royalty-free, fully-paid, non-exclusive, transferable, sublicensable right to use your User Social Media Content including photos,
            text, graphics, audio, video, location information, comments and other
            materials from social media sites in any manner to be determined in
            <b> RENTSTARZ LLC, BROOKLYN MADE KINGS</b> discretion, including but not
            limited to on webpages and social media pages operated by BROOKLYN
            MADE KINGS, e-mails and advertisements, and in any and all other
            marketing, promotional and advertising initiatives, and in any media
            now or hereafter known. RENTSTARZ LLC, BROOKLYN MADE KINGS may
            use, display, produce, distribute, transmit, create derivative works from,
            combine with other materials, alter and/or edit your User Social Media
            Content in any manner in our sole discretion, with no obligation to you
            whatsoever.
            </para>
            <Typography variant="body1" sx={{ mt: 2 }}>
            You grant RENTSTARZ LLC, BROOKLYN MADE KINGS the right to use your
            username, real name, image, likeness, descriptions of you, location or
            other identifying information, including but not limited to your voice, in
            connection with any use of your User Social Media Content.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            You hereby agree and represent and warrant that (i) you are solely
            responsible for your User Social Media Content, (ii) you own all rights in
            and to your User Social Media Content and/or have obtained
            appropriate rights and permissions from any and all other persons and/
            or entities who own, manage or otherwise claim any rights with respect
            to such User Social Media Content, (iii) you are not a minor, (iv)
            BROOKLYN MADE KINGS’s RENTSTARZ LLC use of your User Social Media
            Content as described herein will not violate the rights, including but not
            limited to copyright, trademark, patent, trade secret, privacy, publicity,
            moral, proprietary or other rights, of any third party, or any law, rule or
            regulation, and (v) the User Social Media Content is not libelous,
            defamatory, obscene, pornographic, abusive, indecent, threatening,
            harassing, hateful, or offensive or otherwise unlawful.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            You acknowledge and agree that You will make no monetary or other
            claim against RENTSTARZ LLC, BROOKLYN MADE KINGS for the use of
            the User Social Media Content. You waive the right of prior approval for
            the use of the User Social Media Content. You acknowledge and release
            RENTSTARZ LLC, BROOKLYN MADE KINGS and its assigns, licensees, and
            successors from all claims that may arise regarding the use of the User
            Social Media Content including, but not limited to, any claims of
            defamation, invasion of privacy, or infringement of moral rights, rights
            of publicity, or copyright. RENTSTARZ LLC, BROOKLYN MADE KINGS is
            permitted though not obligated, to include your name as a credit in
            connection with the use of the User Social Media Content.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            User Comments, Feedback and Other Submissions
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            All comments, feedback, suggestions and ideas disclosed, submitted or
            offered to a Covered Party in connection with your use of this Site
            (collectively, "Comments"), shall become and remain the exclusive
            property of RENTSTARZ LLC, BROOKLYN MADE KINGS The Comments
            may be used by a Covered Party in any medium and for any purpose
            worldwide, without obtaining your specific consent and you relinquish
            all rights to such Comments. No Covered Party is under any obligation
            to maintain your Comments (and the use of your first name and first
            initial of your last name with any comments) in confidence, to pay to
            you any compensation for any Comments submitted or to respond to
            any of your Comments. You agree you will be solely responsible for the
            content of any Comments you make.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Termination
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS reserves the right to terminate your account
            and access to the Site and its services at any time. Termination by
            BROOKLYN MADE KINGS may include removal of access to the service,
            deletion of your password, deletion of all related information and files,
            may include the deletion of content associated with your account (or
            any part thereof), and other steps intended to bar your further use of
            the Site and its services. If you become dissatisfied with the Site, your
            sole and exclusive remedy is to immediately discontinue use of the Site.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Amendments to Terms & Conditions
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS reserves the right, at our sole discretion, to
            change, modify or otherwise alter the Terms & Conditions at any time.
            You agree that we may modify the Terms & Conditions and such
            modifications shall be effective immediately upon posting. You agree to
            review these terms and conditions periodically to be aware of
            modifications. Continued access or use of the Site following such
            posting shall be deemed conclusive evidence of your acceptance of the
            modified Terms & Conditions except and to the extent prohibited by
            applicable state or federal law.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Changes to the Site
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            We reserve the right, for any reason, in our sole discretion, to
          terminate, suspend or change any aspect of the Site including but not
          limited to content, prices, features or hours of availability. We may
          impose limits on certain features of the Site or restrict your access to
          any part or all of the Site without notice or penalty. You agree that
          RENTSTARZ LLC, BROOKLYN MADE KINGS will not be liable to you or to
          any third party for any such limitation, modification, change, suspension
          or discontinuance of the Site.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Miscellaneous
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            The captions in these Terms & Conditions are only for convenience and
            do not, in any way, limit or otherwise define the terms and provisions of
            these Terms & Conditions. None of the Covered Parties are responsible
            for any errors or delays in responding to a Request caused by an
            incorrect email address provided by you or other technical problems
            beyond their control. If any provision of the Terms & Conditions is held
            to be invalid or unenforceable by a court of competent jurisdiction,
            then such provision shall be enforced to the maximum extent possible
            so as to effect the intent of the Terms & Conditions, and the remainder
            of the Terms & Conditions shall continue in full force and effect. The
            failure by either you or RENTSTARZ LLC, BROOKLYN MADE KINGS to
            exercise or enforce any right or provision of the Terms & Conditions
            shall not constitute a waiver of such right or provision. You agree that
            any cause of action arising out of or related to the Site or the Terms &
            Conditions must commence within one (1) year after the cause of
            action arose; otherwise, such cause of action is permanently barred. All
            provisions in the Terms & Conditions regarding representations and
            warranties, indemnification, disclaimers, and limitation of liability shall
            survive the termination of the Terms & Conditions.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Entire Agreement
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            These Terms & Conditions, together with the Privacy Policy and those
            terms and conditions incorporated herein or referred to herein,
            constitute the entire agreement (collectively, the "Agreement")
            between you and each Covered Party relating to the subject matter
            hereof, and supersedes any prior understandings or agreements
            (whether oral or written) regarding the subject matter, and may not be
            amended or modified except in writing or by making such amendments
            or modifications available on this Site.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Governing Law & Exclusive Venue for Disputes Not Subject to Arbitration
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            The internal laws of the State of California shall govern the performance
            of these Terms & Conditions, without regard to such state's conflicts of
            laws principles. To the extent any claim, cause of action, or request for
            relief is not subject to arbitration as described below, you consent to
            the exclusive jurisdiction and venue of the state and federal courts
            located in the County of Los Angeles in the State of California, and
            waive any jurisdictional, venue or inconvenient forum objections to
            such courts.
            </Typography>
              <br />
            <para variant="body1" sx={{ mt: 2 }}>
            <u>Notice for Users in California Only.</u> This notice is for users of the Site
            residing in the State of California. Please be advised that the Complaint
            Assistance Unit of the Division of Consumer Services of the California
            Department of Consumer Affairs may be reached by mail at 1625 North
            Market Blvd., Sacramento, CA 95834 or by telephone at (800) 952-5210.
            </para>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Dispute Resolution and Binding Arbitration
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
            Please read this section carefully. It affects your rights.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT
          OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND
          INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW,
          INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN
          YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR USE
          OF THE SITE, PURCHASE FROM BROOKLYN MADE KINGS., OR THE SALE
          OF PRODUCTS OR SERVICES BY BROOKLYN MADE KINGS., WILL BE
          RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION RATHER
          THAN IN COURT. YOU AGREE TO GIVE UP ANY RIGHTS TO LITIGATE
          CLAIMS IN A COURT OR BEFORE A JURY, OR TO PARTICIPATE IN A CLASS
          ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM.
          OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY
          ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION,
          INCLUDING DISCOVERY AND APPEAL RIGHTS. This arbitration provision
          shall survive termination of these Terms & Conditions and the
          termination of your BROOKLYN MADE KINGS account.
            </Typography>

            <br />

            <para variant="body1" sx={{ mt: 2 }}>
            The arbitration will be administered by the American Arbitration
            Association (AAA) under its Consumer Arbitration Rules, as amended by
            these Terms. The Consumer Arbitration Rules are available <i><a href="https://adr.org/sites/default/files/Consumer%20Rules.pdf">online</a></i>. The
            Federal Arbitration Act will govern the interpretation and enforcement
            of this section.
            </para>

            <Typography variant="body1" sx={{ mt: 2 }}>
            The arbitrator, and not any federal, state, or local court or agency, shall
            have exclusive authority to resolve any dispute arising under or relating
            to the interpretation, applicability, enforceability, or formation of these
            Terms, including any claim that all or any part of these Terms are void or
            voidable. The arbitrator will be empowered to grant whatever relief
            would be available in court under law or in equity. Any award of the
            arbitrator(s) will be final and binding on each of the parties and may be
            entered as a judgment in any court of competent jurisdiction.
            </Typography>
           
            <Typography variant="h5" sx={{ mt: 2 }}>
            Notices
            </Typography>
            
            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            Except as explicitly stated otherwise, any notices shall be given by email
            to <i><a href="https://www.uprinting.com/">BROOKLYN MADE KINGS</a></i> at <i><a href="mailto:info@uprinting.com">SLAES@Brooklyn Made Kings</a></i> or to you at
            the email address you provide to BROOKLYN MADE KINGS. Notice shall
            be deemed given 24 hours after the email is sent, unless the sending
            party is notified that the email address is invalid.
            </para>

            <Typography variant="h5" sx={{ mt: 2 }}>
            How do I contact BROOKLYN MADE KINGS?
            </Typography>

            <br />
            <para variant="body1" sx={{ mt: 4 }}>
            We can be reached via email at <i><a href="mailto:SALES@BrooklynMadeKings">SALES@BrooklynMadeKings.com</a></i>
            </para>

            <Typography variant="h5" sx={{ mt: 4 }}>
            ORDER TERMS AND CONDITIONS
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Payment
            </Typography>
            <Typography sx={{ mt: 2 }}>
            All prices and amounts shown on this Site are in U.S. Dollars (USD),
            unless otherwise noted. All prices set forth on the Site and stated
            herein are based on current costs and subject to change without notice
            and payment amounts and other terms may be subject to additional
            agreements between User and BROOKLYN MADE KINGS I RENTSTARZ
            LLC a User submits an order on the Site to purchase Embroidery
            products, promotional products, mailing services, design services or
            other services, the User agrees that all charges, taxes and shipping/
            handling fees will automatically be charged to the credit card or paid by
            User with an approved payment method.
            </Typography>

            <Typography sx={{ mt: 2 }}>
            Production of all items ordered is subject to the terms outlined below in
            the "Embroidery Turnaround Time" section, and will not begin until an
            order is fully paid, including shipping and handling fees, if applicable
            except where RENTSTARZ LLC, BROOKLYN MADE KINGS has explicitly
            agreed to special delayed payment terms with the User prior to placing
            an order. Users who have delayed payment agreements with
            RENTSTARZ LLC, BROOKLYN MADE KINGS are required to provide
            payment in accordance with the terms of their applicable payment
            agreement(s).
            </Typography>
            <Typography sx={{ mt: 2 }}>
            Once the print file(s) associated with an order have been approved by
            the User as described below in the "Embroidery Turnaround Time"
            section, the order will be "In Production" and no changes will be
            allowed to the print files, job characteristics, or Embroidery turnaround
            time. After an order is In Production, the entire amount of the order
            along with applicable taxes and shipping/handling fees shall be deemed
            fully paid and non-refundable, except as provided for in the Return &
            Refund Policy below. Additional Service Fees, List Purchase and USPS
            Postal Costs are non-refundable. Except as provided for in the Return &
            Refund Policy below, any payment received from the User shall be
            deemed fully paid to BROOKLYN MADE KINGS and non-refundable at a
            rate of twenty percent (20%) for each calendar month that has passed
            since the date of the User's initial submission or date of payment,
            whichever is earlier, even where the embroidered job never reaches the
            "In Production" or shipment phase due to no fault of BROOKLYN MADE
            KINGS (e.g. User fails to respond to approval of proof/embroidered job,
            User fails to provide information to complete the embroidered job or
            shipment, User otherwise fails to cancel his or her order prior to
            reaching the "In Production" phase, etc.).
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Return & Refund Policy
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold' }}>
            *Please note, no returns or exchange on any customized products 
            </Typography>
           
              <br />
            <para variant="body1" sx={{ mt: 2 }}>
            Each customer is promised quality of service and premium garments. If there is
            evidence of this not being the case, the customer has <b>30</b> days from receiving the
            product to return it for a refund or an exchange. To be eligible for a return,
            your item must be unused and in the same condition that you received it. It
            must also be in the original packaging.
            </para>

            <Typography variant="body1" sx={{ mt: 2 }}>
            We do not provide prepaid return shipping labels nor do we cover that
            expense. However, we will cover the cost of shipment for the replacement.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            We cannot guarantee that we will receive your returned item. You should
            consider using a trackable shipping service or purchasing shipping insurance.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            If you receive a defective item we will send you a replacement item free of
            charge without the need for you to return the initial item.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            All complaints must be registered within 3 days of receipt of your product .
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            In no case is RENTSTARZ LLC, BROOKLYN MADE KINGS or its affiliates
            liable for loss of business; incidental or consequential damages; or costs
            in excess of billing for services related to the specific job. BROOKLYN
            MADE KINGS is also not liable for returned mail pieces that are
            undeliverable for any reason. ALL PURCHASES OR SALES ARE SUBJECT
            TO THE INDEMNIFICATION, LIMITATION OF LIABILITY, AND DISPUTE
            RESOLUTION AND BINDING ARBITRATION TERMS ABOVE. 
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            If you dispute any charge that is made to your credit/debit card, you
            agree that RENTSTARZ LLC, BROOKLYN MADE KINGS may authorize a
            third party vendor to handle and seek to resolve such dispute on
            BROOKLYN MADE KINGS’s behalf, which may include BROOKLYN MADE
            KINGS providing vendor with the following information: your name,
            physical address, email address, phone number and the first 6 and last 4
            digits of your credit/debit card number. You agree that BROOKLYN
            MADE KINGS may provide such information to the third-party vendor
            and that the third-party vendor may further disclose that information to
            your credit/debit card company, the bank issuing the credit/debit card
            and BROOKLYN MADE KINGS’s merchant processing company for the
            purpose of resolving the dispute. BROOKLYN MADE KINGS will endeavor
            to have the third party vendor agree to maintain the confidentiality of
            such information.
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
            If you dispute valid charges made by BROOKLYN MADE KINGS to your
            credit/debit card and your dispute is subsequently found to be
            fraudulent, you agree to reimburse BROOKLYN MADE KINGSs expenses,
            including bank’s and attorney’s fees incurred in connection with
            resolving the dispute. 
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            REFUNDS
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            If you haven’t received a refund, contact your bank account or credit card
            company. It may take some time before your refund is officially posted. There
            is often some processing time before a refund is processed. Typically it takes
            about two weeks to show up on your bank statement or credit card. 
            </Typography>

            <Typography  variant="body1" sx={{ mt: 2 }}>
            If you haven’t received a refund, contact your bank account or credit card
            company. It may take some time before your refund is officially posted. There
            is often some processing time before a refund is processed. Typically it takes
            about two weeks to show up on your bank statement or credit card. 
            </Typography>

            <br />
            <para  variant="body1" sx={{ mt: 2 }}>
            If you’ve done all of this and you still have not received your refund, please
            contact us at <a href='mailto:sales@brooklynmadekings.com'>sales@brooklynmadekings.com</a>
            </para>

            <Typography  variant="body1" sx={{ mt: 2 }}>
            You will be responsible for paying for your shipping costs for returning your
            item. Shipping costs are non-refundable.
            </Typography>

            <Typography  variant="body1" sx={{ mt: 2 }}>
            A refund will be issued only after the package has arrived at our warehouse.
            We are not able to issue a refund while the package is in transit.
            </Typography>

            <Typography  variant="body1" sx={{ mt: 2 }}>
            It is important that the customer contact us for all inquiries as we will not
            accept unauthorized returns.
            </Typography>


            <Typography variant="h5" sx={{ mt: 2 }}>
            Our Sales Tax Policy
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS charges sales tax on orders picked-up from or
            shipped to addresses in accordance with state and local regulations
            unless you are tax exempt. If you are tax exempt, you need to send or
            fax to us your tax exemption certificate. If, after the User has paid the
            invoice, it is determined that more tax is due, then the User must
            promptly remit the required taxes to the taxing authority or
            immediately reimburse the provider for any additional taxes paid.

            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Promotional and Referral Codes
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS may offer certain promotional codes, referral
            codes or similar promotional coupons ("Promotional Codes") that may
            be redeemed for discounts, or other features or benefits related to the
            Site, subject to any additional terms that BROOKLYN MADE KINGS
            wishes. You agree that Promotional Codes: (a) must be used in a lawful
            manner; (b) must be used for the intended audience and purpose; (c)
            may not be duplicated, sold or transferred in any manner, or made
            available to the general public (whether posted to a public forum,
            coupon collecting service, or otherwise), unless expressly permitted by
            BROOKLYN MADE KINGS; (d) may be disabled or have additional
            conditions applied to them by BROOKLYN MADE KINGS at any time for
            any reason without liability to BROOKLYN MADE KINGS; (e) may only be
            used pursuant to the specific terms that BROOKLYN MADE KINGS
            establishes for such Promotional Code;(f) are not valid for cash or other
            credits or points offered via the Site; and (g) may expire prior to your
            use.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Proofs
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            If requested, an online proof will be available for your review after we
            have received your files for embroidery. Actual time will vary depending
            on our current workload. Jobs eligible for Proof Approval Bypass will be
            sent to press in accordance with the program’s terms. It is the User's
            responsibility to log in to their account and check on their proof. We are
            not liable for delays in the order caused by User's non-approval of the
            proof. An online proof is by no means an accurate color reproduction of
            your final embroidered piece but is the final opportunity for you to
            check the layout, bleeds, crops and final text. Electronic proofs do not
            show transparency and embroidery issues, nor do they show color
            change from RGB or Pantone to CMYK. The proof must be treated as
            independent from the original submitted file and thoroughly reviewed
            prior to approval. It should be checked against the original file for
            possible errors in layout, copy, spacing, punctuation or image
            placement. User is fully responsible for all that is contained in the final
            approved proof. BROOKLYN MADE KINGS offers hard copy proofs which
            show reasonable likeness to the final embroidered pieces. Such proofs
            are recommended for color-critical art and must be requested by the
            User when the embroidery order is made. Hard copy proofs are
            embroidered on a substrate different from the actual paper stock and
            while its main purpose is to show color, variations may occur depending
            on the finish selected (AQ, matte, UV) for the final embroidered job.
            The request for a hard copy proof may involve an additional fee and
            would extend the amount of time needed to complete the job. For
            orders where a hard copy proof has been requested, the approval must
            be received by BROOKLYN MADE KINGS on or before our published cutoff times. We will make every effort to match colors in production when
            a hard copy proof is requested. However, it is the User's responsibility
            to determine if they need a hard copy proof with their embroidery
            order.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Proof Approval Bypass
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            Once a User receives a proof, they have 3 business days to approve or
            reject the proof. In the event a User has taken no action, the proof will
            be deemed to have been approved by User and the order will proceed
            into production.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Cancellation
            </Typography>

            <br />
            <para  variant="body1" sx={{ mt: 2 }}>
            We are able to cancel your order as long as it has not been shipped or in
            production. Any order that is in transit <u>cannot</u> be cancelled.
            </para>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            In order to get a refund you will have to wait for the package to arrive, then
            ship it back to us and once we receive your package we will issue a refund to
            the original payment method.
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            We reserve the right to refuse any order you place with us. We reserve
            the right, but are not obligated, to limit the sales of our products or
            Service to any geographic region or jurisdiction. We may exercise this
            right on a case-by-case basis. We reserve the right to limit the quantities
            of any products or services that we offer. All descriptions of products or
            services, or product or service pricing, are subject to change at any time
            without notice, at our sole discretion. We reserve the right to modify or
            discontinue any product or service at any time. Any offer for any
            product or service made on this site is void where prohibited.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
                Color Accuracy
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS will reproduce color from submitted printready files as closely as possible but cannot exactly match color and
            density (as viewed in a 5000K light booth). Because of inherent
            limitations with the Embroidery process, as well as neighboring image
            ink requirements, the accuracy of color reproduction is not guaranteed.
            By placing an order with BROOKLYN MADE KINGS, you agree to this
            limitation. We will try our best to match the gradient density of each
            color, but we accept no responsibility for color variations between
            submitted files and the final embroidered piece. Under no
            circumstances will a re-embroidery be honored for color variations that
            have occurred during the Embroidery process. We are not liable for
            color matching on proofs that you approve. 
            </Typography>

            <Typography  sx={{ mt: 2 }}>
                No Liability for Errors
            </Typography>
            <Typography  variant="body1" sx={{ mt: 2 }}>
            You, the User, are responsible for ensuring that your order correctly
            specifies size, color, artwork, quantity, style of product, and type of
            product. BROOKLYN MADE KINGS is not responsible for errors made by
            the User during the ordering process. We have made every effort to
            display as accurately as possible on the website the colors, images, and
            artwork you upload onto a product design. We also have made every
            effort to ensure that the proportions and style of all customizable
            products appear as accurately as possible. We cannot guarantee that
            your computer monitor's display of any color, artwork, sizing, or feature
            will be accurate.
            </Typography>

            <Typography  variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS is not liable for errors in a final product caused
            by any of the following reasons:
            </Typography>

            <List sx={{ pl: 4 }}>
            <ListItem>Spelling, punctuation and grammatical errors</ListItem>
            <ListItem>Low resolution or low quality graphics and images</ListItem>
            <ListItem>Damaged fonts</ListItem>
            <ListItem>Transparency issues </ListItem>
            <ListItem>Embroidery issuess</ListItem>
            <ListItem>Artwork files that are not created following our specifications</ListItem>
            <ListItem>Variances in color from the conversion of Pantone or RGB colors to CMYK</ListItem>
            <ListItem>Errors in user-selected options such as size, quantity, garment and finish </ListItem>
            <ListItem>Duplicate orders submitted by the User </ListItem>
            <ListItem>Incorrect files uploaded</ListItem>
            <ListItem>Incorrect file orientation</ListItem>
            <ListItem>Cutting variances</ListItem>
            <ListItem>Minor discolorations</ListItem>
            <ListItem>Application of production markings in areas that will not be visible
            post assembly, including but not limited to QR codes </ListItem>
            <ListItem>Incorrect or undeliverable shipping address</ListItem>
            <ListItem>Damage to products after delivery to User</ListItem>
            </List>

            <Typography variant="body1" sx={{ mt: 2 }}>
            User is responsible for reviewing their files and correcting any issues
          prior to placing the order. We do not warrant that the quality of any
          products, services, information, or other material purchased or
          obtained by you will meet your expectations or other requirements, or
          that any errors in the Service will be corrected in a timely manner or at
          all.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Embroidery Policy
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Due to the gang run method that BROOKLYN MADE KINGS uses, we
            cannot guarantee that every order will be the exact number of items
            which was ordered. BROOKLYN MADE KINGS can only guarantee that it
            will come within plus or minus 10% of the number of items ordered.
            Although rare, in the event of an under run, we will either refund or
            give you Store Credit for the amount of pieces we were "short" if over
            10% of the order quantity. In the case of flexographic and lithographic
            laminated orders, The User will be charged a fee for any items delivered
            above the original item count specified in an order. The additional
            charge will be the unit selling price for the item multiplied by the
            number of items delivered in excess of the items originally ordered plus
            applicable sales tax.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Artwork Files
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Our prepress department checks all submitted artwork files before
            Embroidery , but you are still 100% responsible for the accuracy of your
            print-ready artwork files, and we encourage you to proofread all files
            carefully before submitting to BROOKLYN MADE KINGS. BROOKLYN
            MADE KINGS is not responsible for any issues as to orientation or
            alignment of the pages of your submitted artwork. By submitting the
            artwork to BROOKLYN MADE KINGS, you certify that you have the right
            to use the image(s) in your artwork files. DO NOT send any "one-ofkind" transparencies, prints or artwork. Although we take every
            precaution to safeguard your materials, we are NOT responsible for loss
            or damage of images or artwork.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Under these Terms & Conditions, you agree that you will NOT upload
            any artwork files consisting of the following material: offensive,
            indecent or improper material, nudity, any material that could give rise
            to any civil or criminal liability under applicable law; and any material
            that could infringe rights of privacy, publicity, copyrights or other
            intellectual property rights without the permission of the owner of
            these rights and the persons who are shown in the material if
            applicable. We will refuse an order based on foregoing reasons or for
            any other reason which in our opinion may be illegal in nature or an
            infringement on the rights of any third party. You accept full legal
            liability for the content of material processed and embroidered ed on
            your behalf and under your instructions. We reserve the right to refuse
            an order without disclosing a reason.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS may also provide artwork design tools which
            offer a limited number of elements, including icons, fonts, color
            schemes, and design effects. We reserve the right to use and offer all
            such elements to other parties in the future. Other BROOKLYN MADE
            KINGS Users may use the same design tools to create images that may
            have similar or identical combinations of these elements. BROOKLYN
            MADE KINGS provides no warranty of any kind that artwork created
            using the design tool will not infringe, or be subject to a claim of
            infringing, on the trademark, copyright or other rights of another party.
            It is solely your responsibility to obtain the advice of an attorney
            regarding whether any image, mark, logo name or design is legally
            available for your use and does not infringe on another party's rights.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            We may, based on our sole discretion, set limits to the maximum
            number of days that we shall retain designs or other uploaded files, as
            well as the maximum storage space that we would allocate to such files.
            BROOKLYN MADE KINGS is not responsible for the deletion or failure to
            store any file whether uploaded or designed on our website. We
            reserve the right to delete any file stored which has been inactive for an
            extended period of time, or for any other reason, without prior notice.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Stock Images Terms
            </Typography>
            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS may provides users access to professional
            stock images through an integration with Adobe Stock. The licensing
            fee(s) for any stock image(s) you include in your artwork file will be
            added to your final total during checkout. By purchasing stock image(s)
            in this manner, you agree to adhere to all applicable &nbsp;
            <a href="http://wwwimages.adobe.com/content/dam/acom/en/legal/terms/enterprise/pdfs/AdobeStockEnterpriseTerms_2017v2.pdf" >Adobe Stock agreements.</a>
            </para>

            <Typography variant="body1" sx={{ mt: 2 }}>
            You cannot return or exchange any stock image(s) that you purchase and all sales are final. 
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Embroidery Turnaround Time
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Embroidery turnaround times may vary by certain products. 
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS's responsibility is limited to preparing your
            Embroidery order and turning it over to the carrier for shipping.
            Shipping transit times vary and BROOKLYN MADE KINGS assumes no
            responsibility for delays caused by shipping carriers, weather or any
            damages resulting from the failure to receive a job on time. Your order
            may arrive late due to unforeseen delays in delivery service, the
            breakdown of equipment, illness, etc. 
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            BROOKLYN MADE KINGS is not liable for damages that occur during
            shipping. Pick up orders will be kept for 30 days from the send date of
            the pick-up notification email. If the order has not been picked up from
            BROOKLYN MADE KINGS's facility within 30 days, it will be recycled.
            BROOKLYN MADE KINGS reserves the right to modify the shipping
            option selected by you and retain any related difference in charges
            between shipping options, where BROOKLYN MADE KINGS completes
            the job prior to the turnaround time selected by you and there will be
            no adverse material impact on the target arrival date (see Target Arrival
            section below).
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Target Arrival
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Target arrival dates are calculated by adding the Embroidery
            turnaround time to the shipping transit time. Both Embroidery and
            shipping times are based on business days only and do not include
            weekends or holidays.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            For example, a product with a Embroidery turnaround time of two
            business days and a shipping method of “Two- Day” service would have
            a target arrival date of four business days after your files have been sent
            to production.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            If you have requested a hard copy proof, factor in approximately six
            business days to receive and approve your proof. For next day hard copy
            proofs, factor in an additional two business days.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            Please understand that target arrival dates are estimates, not
            guarantees. BROOKLYN MADE KINGS assumes no responsibility for
            delays caused by shipping carriers, weather, the breakdown of
            equipment, illness, etc.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            International Orders and Shipments
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            We welcome orders from our non-US Users. All transactions will be
            completed in US dollars, using the commercial exchange rate calculated
            by our merchant processor at the time the transaction is completed. No
            adjustments will be made to account for fluctuations in the exchange
            rate between an original transaction and any subsequent refund
            transaction.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            If your shipping destination is outside of the US, you may be required to
            pay taxes (including but not necessarily limited to sales, value-added,
            use and excise taxes), tariffs, import fees, duties and/or other fees,
            charges or assessments related to your order. We recommend
            consulting a Customs Broker to assist with this topic. BROOKLYN MADE
            KINGS does not collect any taxes, fees, duties or other charges or
            assessments for non-US shipments, and you will be responsible for
            paying them at the time of your order's receipt.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Accuracy of Billing and Account Information / Financial Responsibility
            </Typography>
            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            We may, in our sole discretion, limit or cancel quantities purchased per
            account, per household or per order. These restrictions may include
            orders placed by or under the same User account, the same payment &nbsp;
            <u>method (credit/debit card, ACH, Wire Transfer, or other), and/or orders
            that use the same billing and/or shipping address. In the event that we
            make a change to or cancel an order, we may attempt to notify you by
            contacting the e-mail and/or </u>billing address/phone number provided at
            the time the order was made.
            </para>
            <Typography variant="body1" sx={{ mt: 2 }}>
            You agree to provide current, complete and accurate purchase and
            account information for all purchases made on our website. You agree
            to promptly update your account and other information, including your
            email address and credit/debit card numbers and expiration dates or
            banking information for ACH/wire transfer payments, so that we can
            complete your transactions and contact you as needed.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            Miscellaneous
            </Typography>
            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            <u>All materials we create in producing your embroidered product are the
            property of BROOKLYN MADE KINGS. Although these materials will NOT
            be sold to any other party, we reserve the right to distribute free
            samples of your embroidered product. Please note that your</u>&nbsp;
            embroidered product or images used for your embroidered product will
            not be used in any national advertising without your prior written
            consent.
            </para>

          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default TermsAndCondition;
