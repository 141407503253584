import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  CardElement,
  useCartElementState,
  Elements,
} from "@stripe/react-stripe-js";
import { ErrorToaster, SuccessToaster } from "../Toaster/toaster";
import { Grid } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "app/views/Checkout/checkout";

export default function CheckoutForm({ clientSecret,Ship , punchOrder }) {
  // const newElement = useCartElementState();

  const newStripe = useStripe();
  const newElement = useElements();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
        border: errorMessage ? "1px solid red" : "1px solid #ccc",
      },
      invalid: {
        color: "#9e2146",
        iconColor: "#9e2146",
      },
    },
  };

  
  const billingDetails = {
      name: Ship.firstName + ' ' + Ship.lastName, 
      email: Ship.email,
      address: {
        line1: Ship.address, 
        line2: '',
        city:  Ship.city,
        state: Ship.state,
        postal_code: Ship.postalCode,
        country: Ship.country, 
      },
    };

// phoneNo: "+1 (466) 615-7938"
  const handleSubmit = async (e) => {
    setIsLoading(true);
    
    try {
      const { error: stripeError, paymentIntent } =
        await newStripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: newElement.getElement(CardElement),
            billing_details: billingDetails,
          },
          
        });
      if (stripeError) {
        // ErrorToaster(stripeError.message);
        setErrorMessage(stripeError.message);
        return;
      }
      if (paymentIntent.status === "succeeded") {
        SuccessToaster("Payment succeeded");
        punchOrder();
      }
    } catch (e) {
      ErrorToaster(e || "Payment failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs='12' sx={{ my: 2 }}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          {errorMessage && (
          <Grid item xs={12} sx={{ my: 2 }}>
            <span style={{ color: "red" }}>{errorMessage}</span>
          </Grid>
        )}
        </Grid>
      </Grid>
      <button onClick={() => handleSubmit()} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </>
  );
}
