import React, { useEffect, useState } from "react";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { Box, Button, Typography } from "@mui/material";

function Swipper() {
  const [images, setSlides] = useState([]);
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASE_URL;

    fetch(`${process.env.REACT_APP_SERVER_URL}/customer/sliderimages`)
      .then((response) => response.json())
      .then((data) => {
        const fetchedSlides = data.map((item, index) => ({
          id: index,
          imageUrl: baseURL + item.filename,
          name: item.name,
          tagline: item.tagline,
        }));
        setSlides(fetchedSlides);
      })
      .catch((error) => {
        console.error("Error fetching slides:", error);
      });
  }, []);


  return (
    <>
      <Swiper
        style={{ height: "80vh" }}
        centeredSlides={true}
        autoplay={{
          delay: 6500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Box className="SwiperDiv"
             style={{
              // paddingTop: "56.25%", 
              // position: "relative",
              backgroundImage: `url(${image.imageUrl})`, 
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            >
             {/* name */}
              <Typography
                variant="h1"
                component="h2"
                m={"20px"}
                sx={{
                  fontSize: { lg: "120px", xs: "40px" },
                  fontWeight: { sm: "900", xs: "800" },
                }}
              >
                {image.name}
              </Typography>
              {/* tagline */}
              <Typography
                variant="h7"
                sx={{
                  fontSize: { lg: "30px", xs: "15px" },
                  letterSpacing: { lg: "10px", xs: "5px" },
                }}
              >
                {image.tagline}
              </Typography>
            
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Swipper;
