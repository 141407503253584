import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  tabsClasses,
  TextField,
  Typography,
} from "@mui/material";

import NavigationBar from "app/components/navigation/navigationBar";
import React, { Fragment, useRef, useState } from "react";

import CheckroomIcon from "@mui/icons-material/Checkroom";
// Icons
import LocalMallIcon from "@mui/icons-material/LocalMall";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/toaster";
import { ProductServices } from "app/apis/Products/ProductsServices";
import Loader from "app/assets/Loader";
import { DesignToolServices } from "app/apis/DesignTool/DesignTool.Services";
import AbcIcon from "@mui/icons-material/Abc";
import ResponsiveDialog from "app/components/UI/ResponsiveDialog/ResponsiveDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import ImageModal from "app/components/UI/DialogBox/ImageModal";
import CancelIcon from "@mui/icons-material/Cancel";
// Movable
import Moveable from "react-moveable";

// Design Section ------------------------>
import { useDispatch, useSelector } from "react-redux";
import {
  AddDesignData,
  AddTextData,
  removeDesign,
  removeTextData,
  updateDesign,
  updateTextData,
  caluclatePrice,
  AddCustomiseData,
  removeCustomDesign,
  updateCustomDesign,
  AddAllData,
  removeAll,
} from "app/redux/slices/DesignSlice";
import { newObjectId } from "./newObjectId";
import { LoadingButton } from "@mui/lab";
import { DraftOrderServices } from "app/apis/DraftOrder/DraftOrder.Services";
import { imageUpload } from "app/apis/ImageUpload/uploadImage.Services";
import { cartData, removeAllData, removeCartDataItem } from "app/redux/slices/cartDetailSlice";
import { getaquoteservices } from "app/apis/GetAQuote/Getaquote.services";
import { toast } from "react-toastify";

let moveable;

function C_product() {
  const dispatch = useDispatch();

  //Redux Selector
  const DesignInfo = useSelector((state) => state.DesignReducer?.DesignData);
  console.log("🚀 DesignInfo", DesignInfo);
  const TextInfo = useSelector((state) => state.DesignReducer?.TextData);
  console.log("🚀 TextInfo", TextInfo);
  const TotalPrice = useSelector((state) => state.DesignReducer?.TotalPrice);
  const CustomDesign = useSelector(
    (state) => state.DesignReducer?.customiseDesignData
  );
  const AllData = useSelector((state) => state.DesignReducer?.AllData);
  console.log("🚀 AllData", AllData);
  const productData = useSelector((state) => state.cartDetailReducer.cartValue);
  console.log("🚀 productData:", productData);

  const { state } = useLocation();
  console.log("🚀 state", state);

  // USESTATES
  const [open, SetOpen] = useState(false);
  const { id } = useParams();
  const [mainCategoryName, setMainCategoryName] = useState("");
  console.log("🚀 mainCategoryName", mainCategoryName);
  const [subCategoryName, setsubCategoryName] = useState("");
  const [subCategoryNameID, setsubCategoryID] = useState("");
  console.log("🚀subCategoryName", subCategoryName);
  const [Loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [SubLoading, setSubLoading] = useState(false);
  const [Product, setProduct] = useState([]);
  console.log("🚀 Product", Product);
  const [MainImage, setMainImage] = useState({});
  console.log("🚀 ~ MainImage:", MainImage)
  const [MainCategory, setMainCategory] = useState([]);
  console.log("🚀 MainCategory", MainCategory);
  const [SubCategory, setSubCategory] = useState([]);
  console.log("🚀 SubCategory", SubCategory);
  const [selectedMain, setselectedMain] = useState(0); //default selected (0)
  console.log("🚀 selectedMain", selectedMain);
  const [selectedSub, setselectedSub] = useState(0);//default selected (0)
  console.log("🚀 selectedSub", selectedSub);
  const [InchHighlight, setInchHighlight] = useState("");
  console.log("🚀 InchHighlight", InchHighlight);
  const [MainCategoryID, setMainCategoryID] = useState(1);
  const [SubCategoryID, setSubCategoryID] = useState(2);
  console.log("🚀 MainCategoryID", MainCategoryID);
  const [DesignLoading, setDesignLoading] = useState(false);
  const [ProductDesign, setProductDesign] = useState([]);
  console.log("🚀 ProductDesign", ProductDesign);
  const [DesignProductKey, setDesignProductKey] = useState();
  console.log("🚀 ~ DesignProductKey:", DesignProductKey)
  const [openImageModal, setopenImageModal] = useState(false);
  const [selectedModalImage, setselectedModalImage] = useState("");
  console.log("🚀 selectedModalImage", selectedModalImage);
  const [availableColors, setavailableColors] = useState([]);
  const [colors, setColors] = useState([]);
  console.log("🚀 availableColors", availableColors);
  const [selectedColor, setColor] = useState("");
  console.log("🚀 selectedColor", selectedColor);
  const [callCheck, setcallCheck] = useState(0);
  const [message, setMessage] = useState("");
  // Text Change
  const [Text, setText] = useState();
  const [getAllDesignSizes, setgetAllsizes] = useState([]);
  console.log("🚀 getAllDesignSizes", getAllDesignSizes);
  //Select Categories
  const [categories, setCategories] = useState([]);
  console.log("🚀 categories", categories);
  const [categoriesName, setCategoriesName] = useState([]);
  console.log("🚀 categoriesName", categoriesName);
  const [selectCategoryName, setSelectCategoryName] = useState("");
  console.log("🚀 selectCategoryName", selectCategoryName);
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [allDesignProducts, setAllDesignProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [designDetail, setDesignDetail] = useState([]);
  const initialIndices = Array(allDesignProducts.length).fill(0);
  const [currentIndices, setCurrentIndices] = useState(initialIndices);
  console.log("🚀 filterProducts", filterProducts);
  console.log("🚀 allDesignProducts", allDesignProducts);

  const [isDragging, setIsDragging] = useState(false);
  const [withinCriteria, setWithinCriteria] = useState(false);
  const [wasWithinCriteria, setWasWithinCriteria] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [ProductIndex, setProductIndex] = useState(0);
  


  const navigate = useNavigate();

  const ref = useRef();

  const clearRef = () => {
    ref.current.value = "";
  };
 

  // DesignInfo.forEach((e) => {
  //   const image = e.image;
  //   imageArr.push(image);
  // });
  // TextInfo.forEach((e) => {
  //   const image = e.image;
  //   imageArr.push(image);
  // });

  // Tool Main Category
  const getToolMainCategory = async () => {
    try {
      const result = await DesignToolServices.getToolMainCategory();
      if (result.responseCode === 200) {
        setMainCategory(result.data);

        callingSubCategory(result.data[0].id);
      } else {
        ErrorToaster("Oops an Error Occur");
      }
    } catch (e) {
      console.log(e);
      ErrorToaster(e);
    } finally {
    }
  };

  // get  product by id
  const getProductByID = async (id) => {
    setLoading(true);
    try {
      const result = await ProductServices.getProductDetails(id);
      console.log("🚀 getProductByID ~ result:", result);
      if (result.responseCode === 200) {
        setProduct(result.data[0].product_customises_images);
        setMainImage(result.data[0].product_customises_images[0]);
      } else {
        ErrorToaster("Oops an error occur");
      }
    } catch (e) {
      console.log(e);
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  // Handle Main Image
  const HandleMainImage = (Image,index) => {
    setMainImage(Image);
    setProductIndex(index);
    console.log("setProductIndex  ==", ProductIndex)
  };
  

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  
  const checkCriteria = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    if (
      x >= MainImage.left &&
      x <= MainImage.left + MainImage.width &&
      y >= MainImage.top &&
      y <= MainImage.top + MainImage.height
    ) {
      setWithinCriteria(true);
    } else {
      setWithinCriteria(false);
        // alert("Out Of criteria");
    }
  };

  const handleOpacityChange = () => {
    if (!withinCriteria) {
      setWasWithinCriteria(false);
      // alert("Out Of Box");
    } else {
      if (!wasWithinCriteria) {
        setWasWithinCriteria(true);
        // alert("Out Of criteria");
      }
    }
  };

  // MainCategory Handle Option
  const handleMainChange = (event, newValue) => {
    if (newValue === 2) {
      setselectedSub(0);
    }
    console.log("🚀 newValue:", newValue);
    setselectedMain(newValue);
    // for show message
    setselectedSub("");
    // setselectedSub();
    setcallCheck(0);
    setInchHighlight("");
  };
  // Calling Sub Category
  const callingSubCategory = async (id) => {
    setProductDesign([]);
    setColor("");
    setMainCategoryID(id);
    setSubLoading(true);
    setsubCategoryName("");
    try {
      console.log("hrllo");
      const result = await DesignToolServices.getSubCategory(id);
      console.log("🚀 callingSubCategory", result);
      if (result.responseCode === 200) {
        setSubCategory(result.data);
        // result.data[1].id
        if (id === 14) {
          setselectedSub(0);
        }
        if (callCheck === 0) {
          getDesignProduct(result.data[0].id);
          setSubCategoryID(result.data[0].id);
          setsubCategoryName(result.data[0].name);
        }
      } else {
        ErrorToaster("Oops Error occur");
      }
    } catch (e) {
      ErrorToaster(e);
      console.log(e);
    } finally {
      setSubLoading(false);
      setcallCheck(1);
    }
  };

  // if (selectedMain === 2) {
  //   setsubCategoryName("Embroidery");
  //   setSubCategoryID(2);
  // }

  const buttonClicked = () => {
    callingSubCategory(1);
    // setMainCategoryName("HEVI Design");
    setMainCategoryName("BMK DESIGNS");
  };

  const buttonClicked1 = () => {
    setsubCategoryName("Embroidery");
    getDesignProduct(2);
  };

  useEffect(() => {
    buttonClicked();
    //buttonClicked1();
  }, []);

  // Chaning SubCategory
  const handleSubChange = (event, newValue) => {
    setselectedSub(newValue);
    // const getFilterProducts = allDesignProducts.filter(
    //   (item) => item.subcategory_id === newValue
    // );
    // console.log("🚀 getFilterProducts", getFilterProducts);
    // setFilterProducts(getFilterProducts);
  };

  // get DesignProduct by Cat and sub
  const getDesignProduct = async (id, size) => {
    console.log("id, size=>", id, size);
    setText("");
    setColor("");
    setDesignProductKey();
    setavailableColors([]);
    setDesignLoading(true);
    try {
      const result = await DesignToolServices.getCustomiseDesign(
        MainCategoryID,
        id
      );
      console.log("🚀result:", result);
      setAllDesignProducts(result.data);
      if (result.responseCode === 200) {
        if (size) {
          let filteredData = [];
          filteredData = result.data.filter((Design) => {
            if (Design.mono_size === size) {
              console.log("Design=>", Design);
              return Design.customise_detail.map((item) => item);
            }
          });
          console.log("filteredData=>", filteredData);
          if (filteredData.length !== 0) {
            setProductDesign(filteredData);
            setDisabledSelect(false);
          } else {
            setDisabledSelect(true);
            ErrorToaster(`Oops No design available for ${size} size`);
            // setProductDesign(result.data);
          }
        } else {
          setProductDesign(result.data);
        }
      } else {
        ErrorToaster("Oops Error Occur");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDesignLoading(false);
    }
  };

  //   Handle Design  Color change
  const handleColorChange = (event) => {
    if (event.target.value !== selectedColor) {
      SuccessToaster("Color has been changed, now add design");
      setColor(event.target.value);
    }
  };

  // Chaneg product modal
  const HandleDialog = () => {
    SetOpen(!open);
  };

  // Image Modal
  const handleImageModal = async (image) => {
    console.log("🚀 ~ handleImageModal ~ image:", image)
    try {
      const { data } = await DesignToolServices.getDesignDetailById(image.id)
      console.log(data[0].customise_detail);
      // setDesignDetail(data[0].customise_detail);
      if (data) {
        setselectedModalImage(data[0].customise_detail);
      }
    }
    catch (e) {
      console.log(e)
    }
    setopenImageModal(!openImageModal);
  };

  // Handle Click for Design By id

  // const DesignById = async (Product) => {
  //   console.log("Product=>", Product);
  //   // setColor(Product.color_name);
  //   setDesignProductKey(Product.id);
  //   const colors = Product?.customise_detail.map((item) => ({
  //     color_id: item.color_id,
  //     color_code: item.color_code,
  //     color_name: item.color_name
  //   }))
  //   setavailableColors(colors);
  // };

  const setCurrentIndex = (cardIndex, colorIndex) => {
    const newIndices = [...currentIndices];
    console.log("🚀 ~ setCurrentIndex ~ newIndices:", newIndices)
    newIndices[cardIndex] = colorIndex;
    console.log("🚀 ~ setCurrentIndex ~ newIndices[cardIndex] = colorIndex:", newIndices[cardIndex] = colorIndex)
    setCurrentIndices(newIndices);
  };

  //  setselectedDesigns

  // Get All Sizes
  const getAllSizes = async () => {
    try {
      const result = await DesignToolServices.getAllFitType();
      if (result.responseCode === 200) {
        setgetAllsizes(result.data);
      } else {
        ErrorToaster("Oops error occur");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
    }
  };

  //* For Remove Products From Cart List
  const removeCartItem = (id) => {
    dispatch(removeCartDataItem(id));
  };

  // Add Design to Image

  const AddDesign = (Design) => {
    console.log("🚀 ~ AddDesign ~ Design:", Design)
    const newImagesData = [...imageData];
    console.log("🚀 ~ AddDesign ~ newImagesData:", newImagesData)
    console.log("🚀 ~ AddDesign ~ imageData:", imageData)
    if(newImagesData[ProductIndex] && newImagesData[ProductIndex] != null && newImagesData[ProductIndex] != ''){
         ErrorToaster("Please remove the existing image and try again.");
         return;
    }
    newImagesData[ProductIndex] = Design.image; // Set the new image at the specified index
    //newImagesData.push(Design.image);
    console.log("🚀 ~ AddDesign ~ newImagesData:", newImagesData)
    setImageData(newImagesData);
    // For Hevi Design
    try {
      // if (Design && mainCategoryName !== "Custom Design") {
      if (Design && mainCategoryName !== "CUSTOM DESIGNS") {
        // if (selectedColor) {
        if (Design.category_name === "HEVI Design") {
        // if (Design.category_name === "BMK DESIGNS") {
          const uniqueID = newObjectId();
          const designInfo = {
            uniqueID: uniqueID,
            sku: Design.sku,
            size: Design.size,
            id: Design.id,
            category_id: Design.category_id,
            category_name: Design.category_name,
            subcategory_id: Design.subcategory_id,
            subcategory_name: Design.subcategory_name,
            image: Design.image,
            price: Design.price,
            side: MainImage.img_name,
            xaxis: Design.xasis ?? 0,
            yaxis: Design.yaxis ?? 0,
            rotate: Design.rotate ?? "rotate(0deg)",
            color: Design.color_name,
            colorId: Design.color_id,
            colorCode: Design.color_code,
            is_design: 0,
          };
          console.log(designInfo);
          dispatch(AddDesignData(designInfo));
          const designInfocart = {
            uniqueID: uniqueID,
            sku: Design.sku,
            size: Design.size,
            productId: Design.id,
            category_id: Design.category_id,
            category_name: Design.category_name,
            subcategory_id: Design.subcategory_id,
            productName: Design.subcategory_name,
            productImage: Design.image,
            price: Design.price,
            side: MainImage.img_name,
            xaxis: Design.xasis ?? 0,
            yaxis: Design.yaxis ?? 0,
            rotate: Design.rotate ?? "rotate(0deg)",
            color: Design.color_name,
            colorId: Design.color_id,
            colorCode: Design.color_code,
            quantity: state.quantity,
            is_design: 1,
          };
          dispatch(cartData(designInfocart));

          setText("");
          // console.log("Design=>", Design);
        }
        // For MonoGrams
        else if (Design.category_name === "Monograms") {
          // if (Text !== "") {
          const uniqueID = newObjectId();
          const TextDesignDetails = {
            uniqueID: uniqueID,
            sku: Design.sku,
            id: Design.id,
            category_id: Design.category_id,
            category_name: Design.category_name,
            subcategory_id: Design.subcategory_id,
            subcategory_name: Design.subcategory_name,
            image: Design.image,
            xaxis: Design.xasis ?? 0,
            size: Design.mono_size,
            yaxis: Design.yasis ?? 0,
            rotate: Design.rotate ?? "rotate(0deg)",
            price: Design.price,
            side: MainImage.img_name,
            cuscategory_name: Design.customise_cat_name,
            cuscategory_id: Design.customise_cat_id,
            color: Design.color_name,
            colorId: Design.color_id,
            colorCode: Design.color_code,
            is_design: 0,
          };
          dispatch(AddTextData(TextDesignDetails));

          const designInfocart = {
            uniqueID: uniqueID,
            sku: Design.sku,
            size: Design.size,
            productId: Design.id,
            category_id: Design.category_id,
            category_name: Design.category_name,
            subcategory_id: Design.subcategory_id,
            productName: Design.subcategory_name,
            productImage: Design.image,
            price: Design.price,
            side: MainImage.img_name,
            xaxis: Design.xasis ?? 0,
            yaxis: Design.yaxis ?? 0,
            rotate: Design.rotate ?? "rotate(0deg)",
            color: Design.color_name,
            colorId: Design.color_id,
            colorCode: Design.color_code,
            quantity: state.quantity,
            is_design: 1,
          };
          dispatch(cartData(designInfocart));
          // }
          // else {
          //   ErrorToaster("Kindly Enter The Text First!");
          // }
        }
        // For Custom Design
        dispatch(caluclatePrice());
        // } else {
        //   ErrorToaster("Please Select The Color First");
        // }
      } else {
        setImageUrl(`${process.env.REACT_APP_SERVER_URL}${Design}`);
        const uniqueID = newObjectId();
        // const subcategory_id = SubCategory.find(
        //   (item) => item.name === subCategoryName
        // )?.id;
        // const subcategory = SubCategory.find((item) => item.name === subCategoryName);
        // console.log("This is my log subcategory_id =>", subcategory);
        // console.log("This is my log mainCategoryName =>", mainCategoryName);
        // console.log("This is my log subCategoryName =>", subCategoryName);
        const subcategory = SubCategory?.[0].name;
        const subcategory_id = SubCategory?.[0].id;

        console.log("This is my log subcategory_id =>", subcategory_id);
        console.log("This is my log subcategory_id =>", subcategory);

        const designInfo = {
          uniqueID: uniqueID,
          sku: "CUST-01",
          // size: "",
          productId: "",
          category_id: 14,
          category_name: mainCategoryName,
          // subcategory_id: subcategory_id, // 16,
          // subCategoryName === "Upload embroidery designs"
          //   ? 43
          //   : subCategoryName === "Upload vinyl designs"
          //   ? 44
          //   : 45,

          subcategory_id: subcategory_id, // 16,
          subcategory_name: subcategory,

          // subcategory_id: subCategoryNameID, // 16,
          // subcategory_name: subCategoryName,

          // subcategory_name: subCategoryName,
          image: `${process.env.REACT_APP_BASE_URL}${Design}`,
          price: "",
          height: "100px",
          width: "100px",
          side: MainImage.img_name,
          xaxis: "0px",
          yaxis: "0px",
          rotate: "rotate(0deg)",
          is_design: 0,

          productName: 'Custom Design',
          color: "NA",
          size: "NA",
          quantity: 1,
        };
        dispatch(AddCustomiseData(designInfo));
        // dispatch(cartData(designInfo));
      }
      SuccessToaster("Add Customize Design")
    } catch (e) {
    } finally {
      setColor();
      setDesignProductKey();
      setavailableColors([]);
    }
  };

  // get Design By Size
  const getDesignBySize = async (size, Product) => {
    console.log("size=>", size);
    setInchHighlight(size);
    // setDesignLoading(true);
    try {
      const result = await DesignToolServices.getDesignBySizes(size);
      if (result.responseCode === 200) {
        getDesignProduct(Product.subcategory_id, size);
        handleCusCatNameChange();
        getCategoriesName();
        console.log("🚀 result:", result);
      } else {
        ErrorToaster("Oops something went wrong");
      }
    } catch (e) {
      ErrorToaster(e);
    }
    // finally {
    //   setDesignLoading(false);
    // }
  };

  // Remove Design
  const DeleteDesign = (Design,index) => {
    console.log(Design)
    if (Design.category_name === "Monograms") {
      const newImagesData = [...imageData];
      newImagesData[ProductIndex] = null; // Set the specified index to null
      setImageData(newImagesData);    // Update the state with the new array
      removeCartItem(Design)
      dispatch(removeTextData(Design));
    } else if (Design.category_name === "HEVI Design") {
    // } else if (Design.category_name === "BMK DESIGNS") {
      const newImagesData = [...imageData];
      newImagesData[ProductIndex] = null; // Set the specified index to null
      setImageData(newImagesData);    // Update the state with the new array
      removeCartItem(Design)
      dispatch(removeDesign(Design));
      
    } else {
      dispatch(removeCustomDesign(Design));
    }
    dispatch(caluclatePrice());
  };

  // Handle Text Change
  const HandleTextChange = (e) => {
    setText(e.target.value);
  };
  const [DraftLoading, setDraftLoading] = useState(false);
  // Save Draft Order

  const handleSaveDesign = async () => {
    setDraftLoading(true);
    try {
      var Data = [];
      if (DesignInfo.length !== 0) {
        DesignInfo.map((item) => {
          Data.push({
            customise_id: item.id,

            category_id: item.category_id,
            subcategory_id: item.subcategory_id,
            size: item.size,
            side: item.side,
            image: item.image,
            left: item.xaxis,
            top: item.yaxis,
            rotate: item.rotate,
            value: item.value ? item.value : "",
            price: item.price,
            sku: item.sku,
            color: item.color ? item.color : "",
            height: item.height ? item.height : "196px",
            width: item.width ? item.width : "196px",
            is_design: 0,
          });
        });
      }
      if (TextInfo.length !== 0) {
        TextInfo.map((item) => {
          Data.push({
            customise_id: item.id,

            category_id: item.category_id,
            subcategory_id: item.subcategory_id,
            size: item.size,
            side: item.side,
            image: item.image,
            left: item.xaxis,
            top: item.yaxis,
            rotate: item.rotate,
            value: item.value ?? "-",
            price: item.price,
            color: item.color,
            sku: item.sku,
            height: item.height ?? "-",
            width: item.width ?? "-",
            is_design: 0,
          });
        });
      }
      if (CustomDesign.length !== 0) {
        CustomDesign.map((item) => {
          Data.push({
            customise_id: item.id,

            category_id: item.category_id,
            subcategory_id: item.subcategory_id,
            size: item.size,
            side: item.side,
            image: item.image,
            left: item.xaxis,
            top: item.yaxis,
            rotate: item.rotate,
            value: "",
            price: item.price,
            color: "",
            sku: item.sku,
            height: item.height,
            width: item.width,
            is_design: 0,
          });
        });
      }
      if (Data.length !== 0) {
        const FinalData = {
          product_id: id,
          color: state.color,
          size: state.size,
          quantity: state.quantity,
          price: state.price,
          draftorderdetails: Data,
          is_design: 1,
        };
        if (state.type === "new") {
          try {
            const result = await DraftOrderServices.saveOrder(FinalData);

            if (result.responseCode === 200) {
              SuccessToaster("Saved Draft Successfully");
            } else {
              ErrorToaster("Oops Error Occur!");
            }
          } catch (e) {
            ErrorToaster(e);
          } finally {
            setDraftLoading(false);
          }
        } else {
          try {
            const result = await DraftOrderServices.updateDraftOrderById(
              state.id,
              FinalData
            );
            if (result.responseCode === 200) {
              SuccessToaster("Order Updated Successfully");
            } else {
              ErrorToaster("Oops Error Occur!");
            }
          } catch (e) {
            ErrorToaster(e);
          } finally {
            setDraftLoading(false);
          }
        }
      } else {
        ErrorToaster("Theres Nothing to save :/");
      }
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setDraftLoading(false);
    }
  };
  // Save Order
  const saveOrder = () => {
    let ExtraDetail = [];
    if (
      (DesignInfo.length !== 0) |
      (TextInfo.length !== 0) |
      (CustomDesign.length !== 0)
    ) {
      if (DesignInfo.length !== 0) {
        DesignInfo.map((item) => {
          ExtraDetail.push({
            customise_id: item.id,
            category_id: item.category_id,
            subcategory_id: item.subcategory_id,
            size: item.size,
            side: item.side,
            image: item.image,
            left: item.xaxis,
            top: item.yaxis,
            rotate: item.rotate,
            value: "",
            price: item.price,
            sku: item.sku,
            color: "",
            height: item.height ? item.height : "96px",
            width: item.width ? item.width : "96px",
            is_design: 0,
          });
        });
      }

      if (TextInfo.length !== 0) {
        TextInfo.map((item) => {
          ExtraDetail.push({
            customise_id: item.id,

            category_id: item.category_id,
            subcategory_id: item.subcategory_id,
            size: item.size,
            side: item.side,
            image: item.image,
            left: item.xaxis,
            top: item.yaxis,
            rotate: item.rotate,
            value: item.value,
            price: item.price,
            color: item.color,
            sku: item.sku,
            height: "",
            width: "",
            is_design: 0,
          });
        });
      }

      if (CustomDesign.length !== 0) {
        if (CustomDesign.length !== 0) {
          CustomDesign.map((item) => {
            ExtraDetail.push({
            customise_id: item.id,

              category_id: item.category_id,
              subcategory_id: item.subcategory_id,
              size: item.size,
              side: item.side,
              image: item.image,
              left: item.xaxis,
              top: item.yaxis,
              rotate: item.rotate,
              value: "",
              price: item.price,
              color: "",
              sku: item.sku,
              height: item.height,
              width: item.width,
              is_design: 1,
            });
          });
        }
      }
      dispatch(AddAllData(ExtraDetail));

      let obj = {
        productId: state.productId,
        sku:state.sku,
        categoryId: state.categoryId,
        categoryName: state.categoryName,
        price: state.price,
        productImage: state.image,
        productName: state.productName,
        quantity: state.quantity,
        color: state.color,
        color_code: state.colorCode,
        size: state.size,
        is_design: 0, 
      };

      dispatch(cartData([...state.otherProducts, obj].flat()));
      SuccessToaster("Your Design Saved");
      console.log(
        "🚀 [...state.otherProducts, obj].flat():",
        [...state.otherProducts, obj].flat()
      );

      navigate(`/preview/${id}`, {
        state: { Product, state, imageData },
      });
    } else {
      ErrorToaster("Kindly create design first");
    }
    try {
    } catch {
    } finally {
    }
  };

  const [quoteLoading, setQuoteLoading] = useState(false);

  // Get a quote
  const getaquote = async () => {
    setQuoteLoading(true);
    var Data = [];
    if (DesignInfo.length !== 0) {
      DesignInfo.map((item) => {
        console.log("item=>", item);
        Data.push({
          customise_id: item.id,

          category_id: item.category_id,
          subcategory_id: item.subcategory_id,
          size: item.size,
          side: item.side,
          image: item.image,
          left: item.xaxis,
          top: item.yaxis,
          rotate: item.rotate,
          value: "",
          price: item.price,
          sku: item.sku,
          color: "",
          height: "",
          width: "",
          is_design: 0,
        });
      });
    }
    if (TextInfo.length !== 0) {
      TextInfo.map((item) => {
        Data.push({
          customise_id: item.id,

          category_id: item.category_id,
          subcategory_id: item.subcategory_id,
          size: item.size,
          side: item.side,
          image: item.image,
          left: item.xaxis,
          top: item.yaxis,
          rotate: item.rotate,
          value: item.value,
          price: item.price,
          color: item.color,
          sku: item.sku,
          height: "",
          width: "",
          is_design: 0,
        });
      });
    }
    if (CustomDesign.length !== 0) {
      CustomDesign.map((item) => {
        Data.push({
          customise_id: item.id,

          category_id: item.category_id,
          subcategory_id: item.subcategory_id,
          size: item.size ? item.size : null,
          side: item.side,
          image: item.image,
          left: item.xaxis,
          top: item.yaxis,
          rotate: item.rotate,
          value: item.value ? item.value : null,
          price: item.price ? item.price : null,
          color: item.color ? item.color : null,
          sku: item.sku ? item.sku : null,
          height: item.height,
          width: item.width,
          is_design: 0,
        });
      });
    }
    if (Data.length !== 0) {
      const FinalData = {
        product_id: parseInt(id),
        color: state.color ? state.color : null,
        size: state.size ? state.size : null,
        quantity: state.quantity.toString(),
        price: state.price ? state.price.toString() : null,
        message: message,
        quotedetails: Data,
        is_design: 0,
      };
      console.log("FinalData=>", FinalData);
      try {
        const result = await getaquoteservices.getaquoteorder(FinalData);

        if (result.responseCode === 200) {
          setTimeout(() => {
            navigate("/");
          }, 1000);
          SuccessToaster(
            "Your Design is received,we will get back to you soon!"
          );
        } else {
          ErrorToaster("Oops Error Occur!");
        }
      } catch (e) {
        ErrorToaster(e);
      } finally {
        setQuoteLoading(false);
      }
    } else {
      ErrorToaster("Theres Nothing to save ");
    }
  };
  // Customise Design Section
  // Handle Upload Image
  const [imageUrl, setImageUrl] = useState("");
  const HandleUploadImage = async (Data) => {
    SuccessToaster("Your Custom Design is uploading");
    const formData = new FormData();
    formData.append("image", Data.target.files[0]);
    try {
      const result = await imageUpload.UploadImage(formData);
      if (result.responseCode === 200) {
        AddDesign(result.data);
      }
    } catch (e) {
      console.log(e);
      ErrorToaster(e);
    } finally {
      clearRef();
    }
  };
  
  const Maincategories = [
    { id: 1, name: "BMK DESIGNS" },
    { id: 9, name: "Monograms" },
    { id: 14, name: "CUSTOM DESIGNS" }
  ];

  const cleanExistingCart = () => {
    dispatch(removeAllData());
  };

  const getCategoriesName = async () => {
    try {
      const result = await DesignToolServices.getCategoriesName();
      if (result.responseCode === 200) {
        setCategoriesName(result.data);
        console.log("categories=>", categoriesName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handle Customize Category Name Change
  const handleCusCatNameChange = (event) => {
    console.log("value=>", event?.target?.value);
    setSelectCategoryName(event?.target?.value);
    let filteredData = ProductDesign.filter(
      (Design) => Design.customise_cat_id === event?.target?.value.toString()
    );
    console.log("filteredData=>", filteredData);
    setFilterProducts(filteredData);
  };

  useEffect(() => {
    // Set the initial index to 0 for all cards
    setCurrentIndices(initialIndices);
  }, [allDesignProducts]);

  useEffect(() => {
    // getDesignProduct(MainCategoryID, SubCategoryID);
    getProductByID(id);
    getToolMainCategory();
    cleanExistingCart();
    dispatch(removeAll());// remove all design
    getAllSizes();
  }, [URL]);




  return (
    <>
      {Loading ? (
        <Box sx={{ height: { xs: "400px", md: "700px" } }}>
          <Loader />
        </Box>
      ) : (
        <Box sx={{ background: "#fcf0e6", padding: "10px" }}>
          {/* <Box>
            <NavigationBar sx={{ color: "#e46346 !important" }} />
          </Box> */}

          <Box
            sx={{
              padding: { md: "20px", xs: "10px" },
              margin: { md: "20px", xs: "10px" },
              background: "white",
              borderRadius: "30px",
              boxShadow: " rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            {/* <Box>
              <NavigationBar sx={{ color: "#e46346 !important" }} />
            </Box> */}
            <Typography sx={{ display: "flex", gap: "10px" }} variant="p">
              {" "}
              <span>Home</span>/<span>Location</span>{" "}
            </Typography>

            <Grid
              container
              mt={"10px"}
              sx={{ justifyContent: "space-between" }}
            >
              {/* Left Side Nav-------------------------------------------------------> */}
              <Grid
                item
                md="7"
                sm="12"
                xs="12"
                sx={{ mb: { sm: 1.5, xs: 1.5 } }}
              >
                <Grid container>
                  <Grid
                    item
                    xs="12"
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.15) ",
                      borderBottom: "none",
                    }}
                  >
                    {/* Main Category Section--------------------------------------------------> */}
                    {/* <Grid
                      container
                      justifyContent="center"
                      padding={"10px"}
                      alignItems="center"
                      gap="5px"
                    >
                      <Tabs
                        value={selectedMain}
                        onChange={handleMainChange}
                        variant="scrollable"
                        indicatorColor="primary"
                        sx={{
                          ".css-vua1a0-MuiButtonBase-root-MuiTab-root.Mui-selected":
                            { color: "white", background: "black" },
                        }}
                        scrollButtons={true}
                        allowScrollButtonsMobile={true}
                        aria-label="scrollable auto tabs example"
                      >
                        {MainCategory.map((Category, i) => (
                          <Tab
                            key={i}
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              border: "1px solid black",
                              margin: "10px",
                            }}
                            // label={Category.name}
                            label={Maincategories}
                            onMouseEnter={(e) => e.stopPropagation()}
                            onClick={() => {
                              buttonClicked();
                              callingSubCategory(Category.id);
                              setMainCategoryName(Category.name);
                            }}
                          />
                        ))}
                      </Tabs>
                    </Grid> */}

                    <Grid
                          container
                          justifyContent="center"
                          padding={"10px"}
                          alignItems="center"
                          gap="5px"
                        >
                          <Tabs
                            value={selectedMain}
                            onChange={handleMainChange}
                            variant="scrollable"
                            indicatorColor="primary"
                            sx={{
                              ".css-vua1a0-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                                color: "white",
                                background: "black",
                              },
                            }}
                            scrollButtons={true}
                            allowScrollButtonsMobile={true}
                            aria-label="scrollable auto tabs example"
                          >
                            {Maincategories.map((category, i) => (
                              <Tab
                                key={i}
                                sx={{
                                  color: "black",
                                  fontWeight: "700",
                                  border: "1px solid black",
                                  margin: "10px",
                                }}
                                label={category.name}
                                onMouseEnter={(e) => e.stopPropagation()}
                                onClick={() => {
                                  callingSubCategory(category.id);
                                  setMainCategoryName(category.name);
                            }}
                          />
                        ))}
                      </Tabs>
                    </Grid>

                    {/* Sub Category Section--------------------------------------------------> */}
                    {mainCategoryName && (
                      <Grid
                        item
                        xs="12"
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.15) ",
                          width: "100%",
                        }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          gap="20px"
                          color="gray"
                          sx={{
                            padding: { md: "30px", xs: "0px" },
                            width: "100%",
                          }}
                        >
                           {/* <Typography style={{ textAlign: "center", justifyContent:"center" }}>
                            <span style={{ color: "red", fontSize: "16px" }}> Note: </span>{" "}
                            <span style={{ color: "black", fontSize: "15px" }}>Please select a category.</span>
                          </Typography> */}
                          {SubCategory.length !== 0 ? (
                            SubLoading ? (
                              <Box
                                sx={{
                                  padding: "20px",
                                  width: "100%",
                                  justifyContent: "center",
                                }}
                              >
                                <Loader sx={{ marginTop: "0px" }} />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  bgcolor: "background.paper",
                                }}
                              >
                                <Tabs
                                  value={selectedSub}
                                  onChange={handleSubChange}
                                  variant="scrollable"
                                  scrollButtons={true}
                                  allowScrollButtonsMobile={true}
                                  aria-label="visible arrows tabs example"
                                  sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                      "&.Mui-disabled": { opacity: 0.3 },
                                    },
                                    //   display: "flex",
                                    //   justifyContent: "center",
                                    //   ".MuiTabs-flexContainer": {
                                    //     display: "flex",
                                    //     justifyContent: "center",
                                    //   },
                                  }}
                                >
                                  {MainCategoryID !== 14 &&
                                    SubCategory.map((e) => (
                                      <Tab
                                        onClick={() => {
                                          // buttonClicked1();
                                          setsubCategoryName(e.name);
                                          getDesignProduct(e.id);
                                        }}
                                        
                                        // onLoad={() => {
                                        //   setsubCategoryName(e.name);
                                        //   getDesignProduct(e.id);
                                        // }}
                                        label={e.name}
                                        sx={{
                                          color: "black",
                                          fontSize: "15px",
                                          fontWeight: "600",
                                        }}
                                      />
                                    ))}
                                  {MainCategoryID === 14 &&
                                    SubCategory.map((e) =>
                                      e.name === "Embroidery" ? (
                                        <Tab
                                          onClick={() => {
                                            // buttonClicked1();
                                            setsubCategoryName(e.name);
                                            getDesignProduct(e.id);
                                            getDesignProduct(2);
                                            // setsubCategoryID(2)
                                          }}
                                          label={e.name}
                                          sx={{
                                            color: "black",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )
                                    )}
                                </Tabs>
                              </Box>
                            )
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    )}

                    {/* {mainCategoryName === "HEVI Design" && subCategoryName && ( */}
                     {mainCategoryName === "BMK DESIGNS" && subCategoryName && (
                      // DesignLoading ? (
                      //   <Box sx={{ height: { xs: "400px", md: "700px" } }}>
                      //     <Loader />
                      //   </Box>
                      // ) : (
                      <Grid container>
                        {/* Note --------------------> for dresses */}
                        {allDesignProducts.some(
                          (item) => item["subcategory_name"] === "Hats Design"
                        ) ? (
                          <Typography
                            padding={"10px"}
                            sx={{ fontSize: "15px" }}
                          >
                            <span style={{ color: "red", fontWeight: "700" }}>
                              Note:
                            </span>{" "}
                            These Design is only for applicable for Hats
                          </Typography>
                        ) : allDesignProducts.some(
                          (item) =>
                            item["subcategory_name"] === "Sleeves Design"
                        ) ? (
                          <Typography
                            padding={"10px"}
                            sx={{ fontSize: "19px" }}
                          >
                            <span style={{ color: "red", fontWeight: "700" }}>
                              Note:
                            </span>{" "}
                            These Design is only for applicable on Sleeves
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Grid item xs="12">
                          {/* {mainCategoryName === "HEVI Design" && */}
                           {mainCategoryName === "BMK DESIGNS" &&
                            subCategoryName && (
                              <Typography
                                // variant="h6"
                                sx={{ paddingInline: 2, fontSize: "15px" }}
                              >
                                {allDesignProducts.length === 0
                                  ? "No Designs Available"
                                  : "Available Designs:"}
                              </Typography>
                            )}
                        </Grid>
                        {/* Desigin Product */}
                        {/* {allDesignProducts.length !== 0 && ( */}
                        {/* {mainCategoryName === "HEVI Design" && */}
                         {mainCategoryName === "BMK DESIGNS" &&
                          subCategoryName && (
                            <Grid item xs="12" padding="10px 20px" >
                              <Grid
                                container
                                overflow={"scroll"}
                                sx={{ height: "500px" }}
                              >
                                {allDesignProducts.map((design, ind) => (
                                  <Grid item md="4" key={ind}>
                                    <Card
                                      onClick={() => {
                                        setDesignProductKey(design.id);
                                      }}
                                      sx={{
                                        margin: "10px",
                                        cursor: "pointer",
                                        padding: "2px !important",
                                        boxShadow:
                                          DesignProductKey === design.id
                                            ? " rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
                                            : "",
                                      }}
                                    >
                                      <CardMedia
                                        component="img"
                                        height="140"
                                        image={design?.customise_detail[currentIndices[ind]]?.image}
                                        alt="green iguana"
                                        sx={{
                                          objectFit: "contain",
                                          "MuiCardContent-root": {
                                            paddingBottom: "0px",
                                          },
                                        }}
                                        onDoubleClick={() => {
                                          handleImageModal(design);
                                        }}
                                      />
                                      <CardContent
                                        sx={{
                                          paddingBottom: "10px !important",
                                        }}
                                      >
                                        <Typography
                                          gutterBottom
                                          variant="h8"
                                          component="div"
                                        >
                                          <b>Title:</b> {design?.customise_detail[currentIndices[ind]]?.title}
                                        </Typography>

                                        {/* Description of Design---> */}

                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          <b>Size:</b>
                                          {design?.customise_detail[currentIndices[ind]]?.size}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        >
                                          <b>Price:</b> ${design?.customise_detail[currentIndices[ind]]?.price}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="body2"
                                            color="text.secondary"
                                          >
                                            <b>Color:</b>
                                            {design?.customise_detail.map((item, i) => (
                                              <Box key={i}
                                                onClick={() => setCurrentIndex(ind, i)}
                                                component={"span"}
                                                sx={{
                                                  width: "17px",
                                                  height: "17px",
                                                  borderRadius: "50%",
                                                  backgroundColor:
                                                    item.color_code,
                                                  boxShadow:
                                                    "1px 0px 5px 0px #898888",
                                                }}
                                              ></Box>
                                            ))}
                                          </Typography>
                                        </Box>
                                        <Box
                                          display={"flex"}
                                          gap="5px"
                                          justifyContent={"flex-start"}
                                          marginTop="10px"
                                        >
                                          {/* <Button
                                            sx={{
                                              border: "1px solid black",
                                              display: "flex",
                                              fontSize: "12px",
                                              gap: "5px",
                                              color: "black",
                                              fontWeight: "700",
                                              minWidth: "50px",
                                            }}
                                            onClick={() => {
                                              handleImageModal(design);
                                            }}
                                          >
                                            View{" "}
                                            <VisibilityIcon
                                                      sx={{ fontSize: "12px" }}
                                                    />{" "} 
                                          </Button> */}

                                          <Button
                                            fullWidth
                                            onClick={(e) => {
                                              
                                              // e.stopPropagation();
                                              AddDesign({
                                                ...design?.customise_detail[currentIndices[ind]],
                                                category_name: design.category_name,
                                                category_id: design.category_id,
                                                subcategory_name: design.subcategory_name,
                                                subcategory_id: design.subcategory_id,
                                                id: design.id,
                                              });
                                            }}
                                            sx={{
                                              position: "relative",
                                              zIndex: "2",
                                              border: "1px solid black",
                                              display: "flex",
                                              fontSize: "12px",
                                              gap: "5px",
                                              color: "black",
                                              fontWeight: "700",
                                              minWidth: "50px",
                                            }}
                                          >
                                            Add{" "}
                                            {/* <AddIcon sx={{ fontSize: "12px" }} /> */}
                                          </Button>

                                          {/* <Button
                                            onClick={() => {
                                              DesignById(design);
                                            }}
                                            sx={{
                                              position: "relative",
                                              zIndex: "2",
                                              border: "1px solid black",
                                              display: "flex",
                                              fontSize: "9px",
                                              gap: "5px",
                                              color: "black",
                                              fontWeight: "700",
                                              minWidth: "50px"
                                            }}
                                          >
                                            Select Color{" "}
                                          </Button> */}
                                        </Box>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          )}

                        {/* Colors Section----------------------------------------------------------------> */}

                        {availableColors.length !== 0 && (
                          <Grid item xs="12" padding="10px 20px">
                            <Typography variant="h5">
                              Available Colors :
                            </Typography>

                            <FormControl fullWidth sx={{ m: 1 }} size="medium">
                              <InputLabel id="demo-select-small">
                                Colors
                              </InputLabel>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={selectedColor}
                                label="Colors"
                                onChange={handleColorChange}
                              >
                                {availableColors.map((item) => (
                                  <MenuItem value={item?.color_id}>
                                    <Box
                                      display="flex"
                                      gap="10px"
                                      alignItems="center"
                                    >
                                      <Box
                                        sx={{
                                          height: "30px",
                                          width: "30px",
                                          borderRadius: "50%",
                                          backgroundColor: item?.color_code,
                                        }}
                                      ></Box>
                                      {item?.color_name}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {mainCategoryName === "Monograms" && subCategoryName && (
                      <Grid container spacing={2} padding={2}>
                        {/* Sizes Option if available */}
                        <Grid item xs="12" padding="20px">
                          <Grid
                            container
                            alignItems={"center"}
                            justifyContent="start"
                            gap="10px"
                          >
                            {/* text Size if Available */}
                            <Grid item xs="12">
                              {" "}
                              <Typography
                                sx={{
                                  fontWeight: "900",
                                  fontFamily: "Hind, sans-serif",
                                  fontSize: "20px",
                                }}
                              >
                                Text Size:
                              </Typography>
                            </Grid>
                            {mainCategoryName === "Monograms" &&
                              subCategoryName &&
                              getAllDesignSizes.map((size) => (
                                <Grid item xs="2">
                                  {console.log("🚀 ~ size:", size)}
                                  <Typography
                                    onClick={() => {
                                      getDesignBySize(
                                        size.name,
                                        ProductDesign[0]
                                      );
                                      console.log(ProductDesign[0])
                                    }}
                                    cursor="pointer"
                                    sx={{
                                      cursor: "pointer",
                                      padding: "10px",
                                      background:
                                        InchHighlight === size.name
                                          ? "black"
                                          : "white",
                                      color:
                                        InchHighlight === size.name
                                          ? "white"
                                          : "black",
                                      fontWeight: "700",
                                      border: "3px solid black",
                                      borderRadius: "2px",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {size.name}
                                  </Typography>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>

                        {/* Category Dropdown */}
                        <Grid item xs="12">
                          {mainCategoryName === "Monograms" &&
                            subCategoryName &&
                            InchHighlight &&
                            (subCategoryName !== "Numbers" ? (
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Select Category
                                </InputLabel>
                                <Select
                                  disabled={disabledSelect}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectCategoryName}
                                  label="Select Category"
                                  onChange={handleCusCatNameChange}
                                >
                                  {categoriesName.map((item, index) => (
                                    <MenuItem key={index} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <OutlinedInput
                                type="number"
                                value={Text}
                                InputProps={{ maxLength: 3 }}
                                fullWidth
                                endAdornment={
                                  <InputAdornment
                                    position="end"
                                    sx={{ color: "gray" }}
                                  >
                                    123
                                  </InputAdornment>
                                }
                                placeholder="Enter Number Here"
                                textAlign="center"
                                sx={{
                                  ".css-8q2m5j-MuiInputBase-root-MuiInput-root":
                                  {
                                    fontFamily: "Hind, sans-serif",
                                    fontSize: "20px",
                                  },
                                  mt: "10px",
                                }}
                                id="standard-multiline-flexible"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 3);
                                }}
                                variant="standard"
                                onChange={(e) => {
                                  HandleTextChange(e);
                                }}
                              />
                            ))}
                        </Grid>
                        {mainCategoryName === "Monograms" &&
                          subCategoryName &&
                          InchHighlight &&
                          selectCategoryName && (
                            <Grid item xs="12">
                              <Typography variant="h5">
                                {filterProducts.length === 0
                                  ? "No Designs Available"
                                  : "Available Designs:"}
                              </Typography>
                            </Grid>
                          )}

                        {filterProducts.map((design, ind) => (
                          design?.customise_detail.map((item, i) => (
                          <Grid item md="4">
                            <Card
                              onClick={() => {
                                setDesignProductKey(design.id);
                              }}
                              sx={{
                                margin: "10px",
                                cursor: "pointer",
                                padding: "2px !important",
                                boxShadow:
                                  DesignProductKey === design.id
                                    ? " rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px"
                                    : "",
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="140"
                                //image={design?.customise_detail[currentIndices[ind]]?.image}
                                image = {item.image}
                                alt="green iguana"
                                sx={{
                                  objectFit: "contain",
                                  "MuiCardContent-root": {
                                    paddingBottom: "0px",
                                  },
                                }}
                                // onDoubleClick={() => {
                                //   handleImageModal(design);
                                // }}
                              />
                              <CardContent
                                sx={{
                                  paddingBottom: "10px !important",
                                }}
                              >
                                <Typography
                                  gutterBottom
                                  variant="h8"
                                  component="div"
                                >
                                  {/* <b>Title:</b> {design?.customise_detail[currentIndices[ind]]?.title} */}
                                  <b>Title:</b> {item.title}
                                </Typography>

                                {/* Description of Design---> */}

                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  <b>Size:</b>
                                  {design.mono_size}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {/* <b>Price:</b> ${design?.customise_detail[currentIndices[ind]]?.price} */}
                                  <b>Price:</b> ${item.price}
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    <b>Color:</b>
                                    {/* {design?.customise_detail.map((item, i) => (
                                      <Box key={i}
                                        onClick={() => setCurrentIndex(ind, i)}
                                        component={"span"}
                                        sx={{
                                          width: "17px",
                                          height: "17px",
                                          borderRadius: "50%",
                                          backgroundColor: item.color_code,
                                          boxShadow: "1px 0px 5px 0px #898888",
                                        }}
                                      ></Box>
                                    ))} */}
                                    <Box key={i}
                                        onClick={() => setCurrentIndex(ind, i)}
                                        component={"span"}
                                        sx={{
                                          width: "17px",
                                          height: "17px",
                                          borderRadius: "50%",
                                          backgroundColor: item.color_code,
                                          boxShadow: "1px 0px 5px 0px #898888",
                                        }}
                                      ></Box>
                                  </Typography>
                                </Box>
                                <Box
                                  display={"flex"}
                                  gap="5px"
                                  justifyContent={"flex-start"}
                                  marginTop="10px"
                                >
                                  {/* <Button
                                    sx={{
                                      border: "1px solid black",
                                      display: "flex",
                                      fontSize: "12px",
                                      gap: "5px",
                                      color: "black",
                                      fontWeight: "700",
                                      minWidth: "50px",
                                    }}
                                    onClick={() => {
                                      handleImageModal(design);
                                    }}
                                  >
                                    View{" "}
                                    <VisibilityIcon
                                        sx={{ fontSize: "12px" }}
                                      />{" "}
                                  </Button> */}

                                  <Button
                                    fullWidth
                                    onClick={() => {
                                      
                                      // AddDesign({
                                      //   ...design?.customise_detail[currentIndices[ind]],
                                      //   category_name: design.category_name,
                                      //   category_id: design.category_id,
                                      //   subcategory_name: design.subcategory_name,
                                      //   subcategory_id: design.subcategory_id,
                                      //   id: design.id,
                                      //   size: design.mono_size
                                      // });
                                      AddDesign({
                                        ...item,
                                        category_name: design.category_name,
                                        category_id: design.category_id,
                                        subcategory_name: design.subcategory_name,
                                        subcategory_id: design.subcategory_id,
                                        id: design.id,
                                        size: design.mono_size
                                      });
                                    }}
                                    sx={{
                                      position: "relative",
                                      zIndex: "2",
                                      border: "1px solid black",
                                      display: "flex",
                                      fontSize: "12px",
                                      gap: "5px",
                                      color: "black",
                                      fontWeight: "700",
                                      minWidth: "50px",
                                    }}
                                  >
                                    Add{" "}
                                    {/* <AddIcon sx={{ fontSize: "12px" }} /> */}
                                  </Button>

                                  {/* <Button
                                    onClick={() => {
                                      DesignById(design);
                                    }}
                                    sx={{
                                      position: "relative",
                                      zIndex: "2",
                                      border: "1px solid black",
                                      display: "flex",
                                      fontSize: "9px",
                                      gap: "5px",
                                      color: "black",
                                      fontWeight: "700",
                                      minWidth: "50px"
                                    }}
                                  >
                                    Select Color{" "}
                                  </Button> */}
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                          ))
                        ))}

                        {/* Colors Section----------------------------------------------------------------> */}

                        {availableColors.length !== 0 && (
                          <Grid item xs="12" padding="10px 20px">
                            <Typography variant="h5">
                              Available Colors :
                            </Typography>

                            <FormControl fullWidth sx={{ m: 1 }} size="medium">
                              <InputLabel id="demo-select-small">
                                Colors
                              </InputLabel>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={selectedColor}
                                label="Colors"
                                onChange={handleColorChange}
                              >
                                {availableColors.map((item) => (
                                  <MenuItem value={item?.color_id}>
                                    <Box
                                      display="flex"
                                      gap="10px"
                                      alignItems="center"
                                    >
                                      <Box
                                        sx={{
                                          height: "30px",
                                          width: "30px",
                                          borderRadius: "50%",
                                          backgroundColor: item?.color_code,
                                        }}
                                      ></Box>
                                      {item?.color_name}
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {/* If User select Custom Design --------------------------------------> */}
                    {/* {mainCategoryName === "Custom Design" && ( */}
                    {mainCategoryName === "CUSTOM DESIGNS" && (
                      <Grid item xs="12" padding="10px 20px">
                        <Grid container>
                          <Grid item xs="12">
                            <Typography
                              sx={{
                                fontWeight: "900",
                                fontFamily: "Hind, sans-serif",
                                fontSize: "20px",
                              }}
                            >
                              Add Custom Design Here:
                            </Typography>
                            <span
                              class="btn btn-primary btn-file"
                              style={{
                                position: "relative",
                                marginTop: "20px",
                                marginBottom: "20px",
                                overflow: "hidden",
                                background: "black",
                                color: "white",
                                height: "20px",
                                width: "150px",
                                padding: "15px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <CloudUploadIcon sx={{ color: "white" }} /> Upload
                              Image
                              <input
                                ref={ref}
                                style={{
                                  position: " absolute",
                                  top: "0",
                                  right: "0",
                                  minWidth: "100%",
                                  mineight: "100%",
                                  fontSize: " 100px",
                                  align: "right",
                                  filter: "alpha(opacity=0)",
                                  opacity: "0",
                                  outline: "none",
                                  background: "white",
                                  cursor: "inherit",
                                  display: "block",
                                }}
                                type="file"
                                onChange={(e) => {
                                  HandleUploadImage(e);
                                }}
                              ></input>
                            </span>
                          </Grid>
                          <Grid item xs="12">
                            <Typography variant="h6" mb="20px">
                              <span style={{ color: "red" }}> Note: </span>{" "}
                              {/* Please tell us the color and size of your design. */}
                              <span style={{color: "black"}}>
                                  <span style={{ marginBottom: '15px'}}>
                                      Please upload high-quality PNG or JPEG images only.<br/>
                                  </span>
                                  <span style={{ marginLeft: '5%'}}>
                                  Tell us the length and width of your design. <br/>
                                  </span>
                                  <span style={{ marginLeft: '5%'}}>
                                  Please keep in mind the selected placement of your design.<br/>
                                  </span>
                              </span>

                            </Typography>
                            <TextField
                              multiline
                              fullWidth
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                            ></TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* Submit Button */}
                    <Grid item xs="12" mt="20px">
                      {CustomDesign.length !== 0 ? (
                        <LoadingButton
                          fullWidth
                          loading={quoteLoading}
                          onClick={getaquote}
                          sx={{
                            backgroundColor: buttonLoading ? "white" : "black",
                            color: "white",
                            fontSize: "25px",
                            border: "1px solid black",
                            ":hover": {
                              background: "white",
                              color: "black",
                              border: "1px solid black",
                            },
                          }}
                        >
                          Get a quote{" "}
                        </LoadingButton>
                      ) : (
                    <>
                      {selectedSub !== ""  ? 
                      <>
                      <LoadingButton
                      // disable={!selectedSub}
                      loading={buttonLoading}
                      fullWidth
                      onClick={saveOrder}
                      sx={{
                        display: { xs: "none", md: "block" },
                        backgroundColor: buttonLoading ? "white" : "black",
                        color: "white",
                        fontSize: "25px",
                        border: "1px solid black",
                        ":hover": {
                          background: "white",
                          color: "black",
                          border: "1px solid black",
                        },
                      }}
                    >
                      Submit
                    </LoadingButton>
                    </>
                      : 
                      <>
                        <Typography style={{ textAlign: "center", justifyContent:"center" }}>
                        <span style={{ color: "red", fontSize: "20px" }}> Note: </span>{" "}
                        <span style={{ color: "black", fontSize: "20px" }}>Please select a category.</span>
                        </Typography>
                        
                        <LoadingButton
                            disabled
                            loading={buttonLoading}
                            fullWidth
                            onClick={saveOrder}
                            sx={{
                              display: { xs: "none", md: "block" },
                              backgroundColor: buttonLoading ? "white" : "white",
                              color: "white",
                              fontSize: "25px",
                              border: "1px solid black",
                              ":hover": {
                                background: "white",
                                color: "black",
                                border: "1px solid black",
                              },
                            }}
                          >
                            Submit
                          </LoadingButton>
                      </>
                      }
                      </> 
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Right Side Image Section */}
              <Grid
                item
                md="5"
                sm="12"
                xs="12"
                borderLeft={"1px solid rgba(0, 0, 0, 0.09)"}
              >
                <Grid container>
                  {/* Right Navigation-------------------------------------------------> */}
                  <Grid
                    item
                    xs="12"
                    sx={{ order: { xs: 3, sm: 3, md: 1 }, py: 1 }}
                  >
                    <Grid container padding={"0px 10px"}>
                      {/* Left Side --------------------------> */}
                      <Grid item xs="5">
                        <LoadingButton
                          loading={DraftLoading}
                          onClick={() => handleSaveDesign()}
                          sx={{
                            background: "white",
                            color: "black",
                            fontSize: "12px",
                            border: "2px solid black",
                            whiteSpace: "nowrap",
                            px: 1,
                          }}
                        >
                          Save as draft
                        </LoadingButton>
                      </Grid>

                      {/* Right Side ------------------------------------> */}
                      <Grid item xs="7">
                        <Grid
                          container
                          gap="10px"
                          display={"flex"}
                          justifyContent="end"
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.15) ",
                            paddingBottom: "13px",
                          }}
                        >
                          <Button
                            sx={{
                              fontFamily: "Hind, sans-serif",
                              cursor: "pointer",
                              color: "black",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={HandleDialog}
                          >
                            <CheckroomIcon /> Change Product
                          </Button>
                          <Typography
                            sx={{
                              fontWeight: "900",
                              fontFamily: "Hind, sans-serif",
                              fontSize: "16px",
                            }}
                          >
                            Designs Price: ${(TotalPrice).toFixed(2)}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: "900",
                              fontFamily: "Hind, sans-serif",
                              fontSize: "16px",
                            }}
                          >
                            Total Price: ${(TotalPrice + Number(state?.price)).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <LoadingButton
                          loading={buttonLoading}
                          fullWidth
                          onClick={saveOrder}
                          sx={{
                            display: { xs: "block", md: "none" },
                            backgroundColor: buttonLoading ? "white" : "black",
                            color: "white",
                            fontSize: "25px",
                            border: "1px solid black",
                            ":hover": {
                              background: "white",
                              color: "black",
                              border: "1px solid black",
                            },
                          }}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* ======================Design Section ====================== */}

                  {/* Image Upload and Design --------------------------------------> Right side */}
                  <Grid
                    item
                    xs="12"
                    display={"flex"}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      order: { xs: 1, sm: 1, md: 2 },
                      py: 1,
                      // ".moveable-line": { background: "" },
                      ".moveable-control.moveable-origin": {
                        border: "none !important",
                        background: "none !important",
                      },
                    }}
                  >
                    {MainImage.image && (
                      <Box position="relative">
                        <CardMedia
                          component="img"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            position: "relative",
                            bgcolor: MainImage.image && state?.colorCode,
                          }}
                          alt="green iguana"
                          image={MainImage.image}
                        />

                        <Box
                          position={"absolute"}
                          id="RotatableBox"
                          sx={{
                            top: `${MainImage.top}%`,
                            height: `${MainImage.height}%`,
                            width: `${MainImage.width}%`,
                            left: `${MainImage.left}%`,
                            zIndex: "1",
                            border:"2px solid lightgray",
                            // border: withinCriteria ? "2px solid lightgray " :"2px solid red ",
                            borderStyle: "dashed",
                            // overflow: withinCriteria? "hidden" : "none",
                            // opacity: isDragging ? 0 : 1,
                          }}
                        >
                          <Box
                            position={"relative"}
                            sx={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {/* Render Design */}
                            {DesignInfo.length !== 0 &&
                              DesignInfo.map((Design, index) => {
                                if (Design.side === MainImage.img_name) {
                                  return (
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        width: "100%",
                                        height: "100%",
                                        ".rCS1s34yp2 .moveable-control": {
                                          // marginTop: "30px !important",
                                          // marginLeft: "-60px !important",
                                        },

                                        ".rCS1s34yp2 .moveable-rotation .moveable-rotation-control":
                                        {
                                          background: "#e46346",
                                          border: "1px black",
                                        },
                                        ".rCS1s34yp2 .moveable-line": {
                                          display: "none !important ",
                                        },
                                      }}
                                      key={Design.uniqueID}
                                    >
                                      <Box
                                        className={`Design${Design.uniqueID}`}
                                        // onClick={() => {
                                        //   HandleDesignMovement(
                                        //     Design.id,
                                        //     Design,
                                        //     MainImage.img_name
                                        //   );
                                        // }}
                                        sx={{
                                          // border: "1px solid black",
                                          position: "relative",
                                          // height: "80px",
                                          // width: "80px",
                                          left: Design.xaxis,
                                          top: Design.yaxis,
                                          width: "100%",
                                          height: "100%",
                                          transform: Design.rotate
                                            ? Design.rotate
                                            : "rotate(0deg)",
                                        }}
                                        onDragStart={handleDragStart}
                                        onDragEnd={handleDragEnd}
                                        // onLoad={handleOpacityChange} 
                                        onMouseMove={checkCriteria}
                                        onMouseDown={(event) => {
                                          if (withinCriteria) {
                                            event.preventDefault(); 
                                          }
                                        }}
                                      >
                                       <CardMedia
                                        component={"img"}
                                        image={Design.image} 
                                        // onClick={() => {
                                        //   DeleteDesign(Design);
                                        //   ErrorToaster("Remove Design");
                                        // }}
                                        sx={{
                                          position: "relative",
                                          height: "100%",
                                          width: "100%",
                                          transition: "0.1s ease-in-out",
                                          objectFit: "contain",
                                          border: withinCriteria ? "red" : "green", 
                                          ":hover": {
                                            border: "1px solid red",
                                          },
                                          // overflow: withinCriteria? "hidden" : "none",
                                          // opacity: withinCriteria ? 1 : 0.5,
                                        }}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                      />
                                           {/* {isHovered && <CancelIcon sx={{ color: "red", height: "100%",width: "100%"}} />} */}
                                        <Box
                                          onClick={() => {
                                            DeleteDesign(Design,index);
                                            ErrorToaster("Remove Design");
                                          }}
                                          sx={{
                                            position: "absolute",
                                            top: "-25px",
                                            right: "-10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <CancelIcon sx={{ color: "red" }} />
                                        </Box>
                                      </Box>

                                      <Moveable
                                        target={document.getElementsByClassName(
                                          `Design${Design.uniqueID}`
                                        )}
                                        container={null}
                                        origin={true}
                                        edge={false}
                                        snappable={true}
                                        // draggable={true}
                                        hideDefaultLines={true}
                                        throttleDrag={0}
                                        onDragStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log("onDragStart", target);
                                        }}
                                        // resizable={true}
                                        rotatable={true}
                                        onResize={({ target, width, height }) => {
                                          target.style.width = `${width}px`;
                                          target.style.height = `${height}px`;
                                        }}
                                        onRotate={({ target, delta }) => {
                                          target.style.transform = `rotate(${delta}deg)`;
                                        }}
                                        onDrag={({
                                          target,
                                          beforeDelta,
                                          beforeDist,
                                          left,
                                          top,
                                          right,
                                          bottom,
                                          delta,
                                          dist,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.left = `${left}px`;
                                          target.style.top = `${top}px`;
                                        }}
                                        onDragEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                          left,
                                          top,
                                        }) => {
                                          // console.log(Design);
                                          if (
                                            Design.category_name ===
                                            // "HEVI Design"
                                            "BMK DESIGNS"
                                          ) {
                                            // const DesignObj = {
                                            //   uniqueID: Design.uniqueID,
                                            //   sku: Design.sku,
                                            //   id: Design.id,
                                            //   category_id: Design.category_id,
                                            //   category_name:
                                            //     Design.category_name,
                                            //   subcategory_id:
                                            //     Design.subcategory_id,
                                            //   subcategory_name:
                                            //     Design.subcategory_name,
                                            //   image: Design.image,
                                            //   price: Design.price,
                                            //   size: Design.size,
                                            //   rotate: Design.rotate,
                                            //   side: Design.side,
                                            //   yaxis: target.style.top,
                                            //   xaxis: target.style.left,
                                            // };
                                            // dispatch(updateDesign(DesignObj));
                                            const updatedData = {
                                              uniqueID: Design.uniqueID,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name: Design.category_name,
                                              subcategory_id: Design.subcategory_id,
                                              subcategory_name: Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              rotate: target.style.transform,
                                              side: Design.side,
                                              yaxis: target.style.top,
                                              xaxis: target.style.left,
                                              size: Design.size,
                                              color: Design.color,
                                              value: Design.value,
                                              width: target.style.width,
                                              height: target.style.height
                                            };
                                            // if (Design.category_name === "HEVI Design") {
                                            if (Design.category_name === "BMK DESIGNS") {
                                              dispatch(updateDesign(updatedData));
                                            } else if (Design.category_name === "Monograms") {
                                              dispatch(updateTextData(updatedData));
                                            }
                                          }
                                          if (
                                            Design.category_name === "Monograms"
                                          ) {
                                            const DesignObj = {
                                              value: Design.value,
                                              uniqueID: Design.uniqueID,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name:
                                                Design.category_name,
                                              subcategory_id:
                                                Design.subcategory_id,
                                              subcategory_name:
                                                Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              rotate: Design.rotate,
                                              side: Design.side,
                                              yaxis: target.style.top,
                                              xaxis: target.style.left,
                                              color: Design.color,
                                              size: Design.size,
                                              width: target.style.width,
                                              height: target.style.height
                                            };
                                            dispatch(updateTextData(DesignObj));
                                          }
                                        }}
                                        /* When resize or scale, keeps a ratio of the width, height. */
                                        keepRatio={true}
                                        /* resizable*/
                                        /* Only one of resizable, scalable, warpable can be used. */
                                        // resizable={false}
                                        throttleResize={0}
                                        onResizeStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log("onResizeStart", target);
                                        }}
                                        // onResize={({
                                        //   target,
                                        //   width,
                                        //   height,
                                        //   dist,
                                        //   delta,
                                        //   direction,
                                        //   clientX,
                                        //   clientY,
                                        // }) => {
                                        //   delta[0] &&
                                        //     (target.style.width = `${width}px`);
                                        //   delta[1] &&
                                        //     (target.style.height = `${height}px`);
                                        // }}
                                        onResizeEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log(
                                          //   "onResizeEnd",
                                          //   target,
                                          //   isDrag
                                          // );
                                        }}
                                        /* scalable */
                                        /* Only one of resizable, scalable, warpable can be used. */
                                        // scalable={true}
                                        throttleScale={0}
                                        onScaleStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        onScale={({
                                          target,
                                          scale,
                                          dist,
                                          delta,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.transform = transform;
                                        }}
                                        onScaleEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log(
                                          //   "onScaleEnd",
                                          //   target,
                                          //   isDrag
                                          // );
                                        }}
                                        /* rotatable */
                                        rotatable={true}
                                        throttleRotate={0}
                                        onRotateStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        onRotate={({
                                          target,
                                          delta,
                                          dist,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.transform = transform;
                                        }}
                                        onRotateEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          if (
                                            Design.category_name ===
                                            // "HEVI Design"
                                            "BMK DESIGNS"
                                          ) {
                                            const DesignObj = {
                                              uniqueID: Design.uniqueID,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name:
                                                Design.category_name,
                                              subcategory_id:
                                                Design.subcategory_id,
                                              subcategory_name:
                                                Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              side: Design.side,
                                              yaxis: Design.yaxis,
                                              xaxis: Design.xaxis,
                                              rotate: target.style.transform,
                                              size: Design.size,
                                              width: target.style.width,
                                              height: target.style.height
                                            };
                                            dispatch(updateDesign(DesignObj));
                                          } else if (
                                            Design.category_name === "Monograms"
                                          ) {
                                            const TextObj = {
                                              uniqueID: Design.uniqueID,
                                              value: Design.value,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name:
                                                Design.category_name,
                                              subcategory_id:
                                                Design.subcategory_id,
                                              subcategory_name:
                                                Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              side: Design.side,
                                              yaxis: Design.yaxis,
                                              xaxis: Design.xaxis,
                                              rotate: target.style.transform,
                                              color: Design.color,
                                              size: Design.size,
                                              width: target.style.width,
                                              height: target.style.height
                                            };
                                            dispatch(updateTextData(TextObj));
                                          }

                                          // console.log(
                                          //   "onRotateEnd",
                                          //   target,
                                          //   isDrag
                                          // );
                                        }}
                                        // Enabling pinchable lets you use events that
                                        // can be used in draggable, resizable, scalable, and rotateable.
                                        pinchable={true}
                                        onPinchStart={({
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinchStart event occur before dragStart, rotateStart, scaleStart, resizeStart
                                          // console.log("onPinchStart");
                                        }}
                                        onPinch={({
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinch event occur before drag, rotate, scale, resize
                                          // console.log("onPinch");
                                        }}
                                        onPinchEnd={({
                                          isDrag,
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinchEnd event occur before dragEnd, rotateEnd, scaleEnd, resizeEnd
                                          // console.log("onPinchEnd");
                                        }}
                                      />
                                    </Box>
                                  );
                                }
                              })}

                            {/* Render Text Design  */}
                            {TextInfo.length !== 0 &&
                              TextInfo.map((Design,index) => {
                                if (Design.side === MainImage.img_name) {
                                  return (
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        width: "100%",
                                        height: "100%",
                                        ".rCS1s34yp2 .moveable-control": {
                                          // marginTop: "30px !important",
                                          // marginLeft: "-200%px !important",
                                        },

                                        ".rCS1s34yp2 .moveable-rotation .moveable-rotation-control":
                                        {
                                          background: "#e46346",
                                          border: "1px black",
                                        },
                                        ".rCS1s34yp2 .moveable-line": {
                                          display: "none !important ",
                                        },
                                      }}
                                      key={Design.uniqueID}
                                    >
                                      <Box
                                        className={`Design${Design.uniqueID}`}
                                        sx={{
                                          position: "relative",
                                          ":hover": {
                                            border: "1px solid red",
                                          },
                                          left: Design.xaxis,
                                          top: Design.yaxis,
                                          width: "100%",
                                          height: "100%",
                                          transform: Design.rotate
                                            ? Design.rotate
                                            : "rotate(0deg)",
                                          // height: "80px",
                                          // width: "80px",
                                        }}
                                      >
                                        <CardMedia
                                          component={"img"}                                          
                                          src={Design.image}
                                          alt={"design image"}

                                          sx={{
                                            position: "relative",
                                            height: "100%",
                                            width: "100%",
                                            transition: "0.1s ease-in-out",
                                            objectFit: "contain",
                                            border: withinCriteria ? "red" : "green", 
                                            ":hover": {
                                              border: "1px solid red",
                                            },
                                            // overflow: withinCriteria? "hidden" : "none",
                                            // opacity: withinCriteria ? 1 : 0.5,
                                          }}
                                        />
                                        <Typography
                                          variant="h4"
                                          sx={{
                                            cursor: "pointer",
                                            fontFamily:
                                              "Dancing Script, cursive",
                                            color: Design.color,
                                          }}
                                        >
                                          {" "}
                                          {Design.value}
                                        </Typography>
                                        <Box
                                          onClick={() => {
                                            DeleteDesign(Design,index);
                                            ErrorToaster("Remove Design");
                                          }}
                                          sx={{
                                            position: "absolute",
                                            top: "-20px",
                                            right: "-20px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <CancelIcon sx={{ color: "red" }} />
                                        </Box>
                                      </Box>
                                      <Moveable
                                        target={document.getElementsByClassName(
                                          `Design${Design.uniqueID}`
                                        )}
                                        container={null}
                                        origin={true}
                                        /* Resize event edges */
                                        edge={false}
                                        // bounds={{
                                        //   left: 200,
                                        //   top: 200,
                                        //   right: 200,
                                        //   bottom: 200,
                                        // }}
                                        snappable={true}
                                        // top: `${MainImage.top}%`,
                                        // height: `${MainImage.height}%`,
                                        // width: `${MainImage.width}%`,
                                        // left: `${MainImage.left}%`,
                                        /* draggable */
                                        // draggable={true}
                                        hideDefaultLines={true}
                                        throttleDrag={0}
                                        onDragStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log("onDragStart", target);
                                        }}
                                        onDrag={({
                                          target,
                                          beforeDelta,
                                          beforeDist,
                                          left,
                                          top,
                                          right,
                                          bottom,
                                          delta,
                                          dist,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.left = `${left}px`;
                                          target.style.top = `${top}px`;

                                          // target.style.top = top;
                                          // target.style.left = left;
                                        }}
                                        onDragEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                          left,
                                          top,
                                        }) => {
                                          if (
                                            Design.category_name ===
                                            // "HEVI Design"
                                            "BMK DESIGNS"
                                          ) {
                                            const DesignObj = {
                                              uniqueID: Design.uniqueID,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name:
                                                Design.category_name,
                                              subcategory_id:
                                                Design.subcategory_id,
                                              subcategory_name:
                                                Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              size: Design.size,
                                              rotate: Design.rotate,
                                              side: Design.side,
                                              yaxis: target.style.top,
                                              xaxis: target.style.left,
                                            };
                                            dispatch(updateDesign(DesignObj));
                                          }
                                          if (
                                            Design.category_name === "Monograms"
                                          ) {
                                            const DesignObj = {
                                              value: Design.value,
                                              uniqueID: Design.uniqueID,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name:
                                                Design.category_name,
                                              subcategory_id:
                                                Design.subcategory_id,
                                              subcategory_name:
                                                Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              rotate: Design.rotate,
                                              side: Design.side,
                                              yaxis: target.style.top,
                                              xaxis: target.style.left,
                                              color: Design.color,
                                              size: Design.size,
                                            };
                                            dispatch(updateTextData(DesignObj));
                                          }
                                        }}
                                        /* When resize or scale, keeps a ratio of the width, height. */
                                        keepRatio={true}
                                        /* resizable*/
                                        /* Only one of resizable, scalable, warpable can be used. */
                                        // resizable={false}
                                        throttleResize={0}
                                        onResizeStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log("onResizeStart", target);
                                        }}
                                        onResize={({
                                          target,
                                          width,
                                          height,
                                          dist,
                                          delta,
                                          direction,
                                          clientX,
                                          clientY,
                                        }) => {
                                          delta[0] &&
                                            (target.style.width = `${width}px`);
                                          delta[1] &&
                                            (target.style.height = `${height}px`);
                                        }}
                                        onResizeEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log(
                                          //   "onResizeEnd",
                                          //   target,
                                          //   isDrag
                                          // );
                                        }}
                                        /* scalable */
                                        /* Only one of resizable, scalable, warpable can be used. */
                                        // scalable={true}
                                        throttleScale={0}
                                        onScaleStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        onScale={({
                                          target,
                                          scale,
                                          dist,
                                          delta,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.transform = transform;
                                        }}
                                        onScaleEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log(
                                          //   "onScaleEnd",
                                          //   target,
                                          //   isDrag
                                          // );
                                        }}
                                        /* rotatable */
                                        rotatable={true}
                                        throttleRotate={0}
                                        onRotateStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        onRotate={({
                                          target,
                                          delta,
                                          dist,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.transform = transform;
                                        }}
                                        onRotateEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          if (
                                            Design.category_name ===
                                            // "HEVI Design"
                                            "BMK DESIGNS"
                                          ) {
                                            const DesignObj = {
                                              uniqueID: Design.uniqueID,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name:
                                                Design.category_name,
                                              subcategory_id:
                                                Design.subcategory_id,
                                              subcategory_name:
                                                Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              side: Design.side,
                                              yaxis: Design.yaxis,
                                              xaxis: Design.xaxis,
                                              rotate: target.style.transform,
                                              size: Design.size,
                                            };
                                            dispatch(updateDesign(DesignObj));
                                          } else if (
                                            Design.category_name === "Monograms"
                                          ) {
                                            const TextObj = {
                                              uniqueID: Design.uniqueID,
                                              value: Design.value,
                                              sku: Design.sku,
                                              id: Design.id,
                                              category_id: Design.category_id,
                                              category_name:
                                                Design.category_name,
                                              subcategory_id:
                                                Design.subcategory_id,
                                              subcategory_name:
                                                Design.subcategory_name,
                                              image: Design.image,
                                              price: Design.price,
                                              side: Design.side,
                                              yaxis: Design.yaxis,
                                              xaxis: Design.xaxis,
                                              rotate: target.style.transform,
                                              color: Design.color,
                                              size: Design.size,
                                            };
                                            dispatch(updateTextData(TextObj));
                                          }

                                          // console.log(
                                          //   "onRotateEnd",
                                          //   target,
                                          //   isDrag
                                          // );
                                        }}
                                        // Enabling pinchable lets you use events that
                                        // can be used in draggable, resizable, scalable, and rotateable.
                                        pinchable={true}
                                        onPinchStart={({
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinchStart event occur before dragStart, rotateStart, scaleStart, resizeStart
                                          // console.log("onPinchStart");
                                        }}
                                        onPinch={({
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinch event occur before drag, rotate, scale, resize
                                          // console.log("onPinch");
                                        }}
                                        onPinchEnd={({
                                          isDrag,
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinchEnd event occur before dragEnd, rotateEnd, scaleEnd, resizeEnd
                                          // console.log("onPinchEnd");
                                        }}
                                      />
                                    </Box>
                                  );
                                }
                              })}

                            {/* Render Custom Design */}
                            {CustomDesign.length !== 0 &&
                              CustomDesign.map((Design) => {
                                console.log("Design", Design);
                                if (Design.side === MainImage.img_name) {
                                  return (
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        width: "100%",
                                        height: "100%",
                                        ".rCS1s34yp2 .moveable-control": {
                                          // marginTop: "30px !important",
                                          // marginLeft: "-200%px !important",
                                        },

                                        ".rCS1s34yp2 .moveable-rotation .moveable-rotation-control":
                                        {
                                          background: "#e46346",
                                          border: "1px black",
                                        },
                                        ".rCS1s34yp2 .moveable-line": {
                                          display: "none !important ",
                                        },
                                      }}
                                      key={Design.uniqueID}
                                    >
                                      <Box
                                        className={`Design${Design.uniqueID}`}
                                        sx={{
                                          position: "relative",
                                          // height:
                                          //   Design.height !== ""
                                          //     ? Design.height
                                          //     : "80px",
                                          // width:
                                          //   Design.width !== ""
                                          //     ? Design.width
                                          //     : "80px",
                                          width: "100%",
                                          height: "100%",
                                          left: Design.xaxis,
                                          top: Design.yaxis,
                                          transform: Design.rotate
                                            ? Design.rotate
                                            : "rotate(0deg)",
                                        }}
                                      >
                                        <CardMedia
                                          component={"img"}
                                          image={Design.image}
                                          // onClick={() => {
                                          //   DeleteDesign(Design);
                                          //   ErrorToaster("Remove Design");
                                          // }}
                                          sx={{
                                            position: "relative",
                                            height: "100%",
                                            width: "100%",
                                            transition: "0.1s ease-in-out",
                                            objectFit: "contain",
                                            ":hover": {
                                              border: "1px solid red",
                                            },
                                          }}
                                        />
                                        <Box
                                          onClick={() => {
                                            DeleteDesign(Design);
                                            ErrorToaster("Remove Design");
                                          }}
                                          sx={{
                                            position: "absolute",
                                            top: "-25px",
                                            right: "-10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <CancelIcon sx={{ color: "red" }} />
                                        </Box>
                                      </Box>
                                      <Moveable
                                        target={document.getElementsByClassName(
                                          `Design${Design.uniqueID}`
                                        )}
                                        container={null}
                                        origin={true}
                                        /* Resize event edges */
                                        edge={false}
                                        // bounds={{
                                        //   left: 200,
                                        //   top: 200,
                                        //   right: 200,
                                        //   bottom: 200,
                                        // }}
                                        snappable={true}
                                        // top: `${MainImage.top}%`,
                                        // height: `${MainImage.height}%`,
                                        // width: `${MainImage.width}%`,
                                        // left: `${MainImage.left}%`,
                                        /* draggable */
                                        // draggable={true}
                                        hideDefaultLines={true}
                                        throttleDrag={0}
                                        onDragStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        // resizable={true}
                                        rotatable={true}
                                        onResize={({ target, width, height }) => {
                                          target.style.width = `${width}px`;
                                          target.style.height = `${height}px`;
                                        }}
                                        onRotate={({ target, delta }) => {
                                          target.style.transform = `rotate(${delta}deg)`;
                                        }}
                                        onDrag={({
                                          target,
                                          beforeDelta,
                                          beforeDist,
                                          left,
                                          top,
                                          right,
                                          bottom,
                                          delta,
                                          dist,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.left = `${left}px`;
                                          target.style.top = `${top}px`;

                                          // target.style.top = top;
                                          // target.style.left = left;
                                        }}
                                        onDragEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                          left,
                                          top,
                                        }) => {
                                          // const DesignObj = {
                                          //   uniqueID: Design.uniqueID,
                                          //   sku: "",
                                          //   id: Design.id,
                                          //   category_id: Design.category_id,
                                          //   category_name: Design.category_name,
                                          //   subcategory_id:
                                          //     Design.subcategory_id,
                                          //   subcategory_name:
                                          //     Design.subcategory_name,
                                          //   height: Design.height,
                                          //   width: Design.width,
                                          //   image: Design.image,
                                          //   price: Design.price,
                                          //   side: Design.side,
                                          //   yaxis: target.style.top,
                                          //   xaxis: target.style.left,
                                          //   rotate: Design.rotate,
                                          //   size: Design.size,
                                          // };
                                          // dispatch(
                                          //   updateCustomDesign(DesignObj)
                                          // );
                                          const updatedData = {
                                            uniqueID: Design.uniqueID,
                                            sku: Design.sku,
                                            id: Design.id,
                                            category_id: Design.category_id,
                                            category_name: Design.category_name,
                                            subcategory_id: Design.subcategory_id,
                                            subcategory_name: Design.subcategory_name,
                                            image: Design.image,
                                            price: Design.price,
                                            rotate: target.style.transform,
                                            side: Design.side,
                                            yaxis: target.style.top,
                                            xaxis: target.style.left,
                                            size: Design.size,
                                            color: Design.color,
                                            value: Design.value,
                                          };
                                          // if (Design.category_name === "HEVI Design") {
                                          if (Design.category_name === "BMK DESIGNS") {
                                            dispatch(updateDesign(updatedData));
                                          } else if (Design.category_name === "Monograms") {
                                            dispatch(updateTextData(updatedData));
                                          }
                                        }}
                                        /* When resize or scale, keeps a ratio of the width, height. */
                                        keepRatio={true}
                                        /* resizable*/
                                        /* Only one of resizable, scalable, warpable can be used. */
                                        // resizable={true}
                                        throttleResize={0}
                                        onResizeStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log("onResizeStart", target);
                                        }}
                                        // onResize={({
                                        //   target,
                                        //   width,
                                        //   height,
                                        //   dist,
                                        //   delta,
                                        //   direction,
                                        //   clientX,
                                        //   clientY,
                                        // }) => {
                                        //   // console.log(
                                        //   //   "onResize",
                                        //   //   width,
                                        //   //   height
                                        //   // );

                                        //   const DesignObj = {
                                        //     uniqueID: Design.uniqueID,
                                        //     sku: Design.sku,
                                        //     id: Design.id,
                                        //     category_id: Design.category_id,
                                        //     category_name: Design.category_name,
                                        //     subcategory_id:
                                        //       Design.subcategory_id,
                                        //     subcategory_name:
                                        //       Design.subcategory_name,
                                        //     height: `${height}px`,
                                        //     width: `${width}px`,
                                        //     image: Design.image,
                                        //     price: Design.price,
                                        //     side: Design.side,
                                        //     yaxis: Design.yaxis,
                                        //     xaxis: Design.xaxis,
                                        //     rotate: Design.rotate,
                                        //     size: Design.size,
                                        //   };
                                        //   dispatch(
                                        //     updateCustomDesign(DesignObj)
                                        //   );

                                        //   delta[0] &&
                                        //     (target.style.width = `${width}px`);
                                        //   delta[1] &&
                                        //     (target.style.height = `${height}px`);
                                        // }}
                                        onResizeEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        /* scalable */
                                        /* Only one of resizable, scalable, warpable can be used. */
                                        // scalable={true}
                                        throttleScale={0}
                                        onScaleStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        onScale={({
                                          target,
                                          scale,
                                          dist,
                                          delta,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.transform = transform;
                                        }}
                                        onScaleEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          // console.log(
                                          //   "onScaleEnd",
                                          //   target,
                                          //   isDrag
                                          // );
                                        }}
                                        /* rotatable */
                                        rotatable={true}
                                        throttleRotate={0}
                                        onRotateStart={({
                                          target,
                                          clientX,
                                          clientY,
                                        }) => { }}
                                        onRotate={({
                                          target,
                                          delta,
                                          dist,
                                          transform,
                                          clientX,
                                          clientY,
                                        }) => {
                                          target.style.transform = transform;
                                        }}
                                        onRotateEnd={({
                                          target,
                                          isDrag,
                                          clientX,
                                          clientY,
                                        }) => {
                                          const DesignObj = {
                                            uniqueID: Design.uniqueID,
                                            sku: "",
                                            id: Design.id,
                                            category_id: Design.category_id,
                                            category_name: Design.category_name,
                                            subcategory_id:
                                              Design.subcategory_id,
                                            subcategory_name:
                                              Design.subcategory_name,
                                            height: Design.height,
                                            width: Design.width,
                                            image: Design.image,
                                            price: Design.price,
                                            side: Design.side,
                                            yaxis: Design.yaxis,
                                            xaxis: Design.xaxis,
                                            rotate: target.style.transform,
                                            size: Design.size,
                                          };
                                          dispatch(
                                            updateCustomDesign(DesignObj)
                                          );
                                        }}
                                        // Enabling pinchable lets you use events that
                                        // can be used in draggable, resizable, scalable, and rotateable.
                                        pinchable={true}
                                        onPinchStart={({
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinchStart event occur before dragStart, rotateStart, scaleStart, resizeStart
                                          // console.log("onPinchStart");
                                        }}
                                        onPinch={({
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinch event occur before drag, rotate, scale, resize
                                          // console.log("onPinch");
                                        }}
                                        onPinchEnd={({
                                          isDrag,
                                          target,
                                          clientX,
                                          clientY,
                                          datas,
                                        }) => {
                                          // pinchEnd event occur before dragEnd, rotateEnd, scaleEnd, resizeEnd
                                          // console.log("onPinchEnd");
                                        }}
                                      />
                                    </Box>
                                  );
                                }
                              })}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>

                  {/* All Images Section */}

                  <Grid
                    item
                    xs="12"
                    mt="20px"
                    sx={{ order: { xs: 2, sm: 2, md: 3 }, py: 1 }}
                  >
                    <Grid
                      container
                      paddingLeft={"20px"}
                      gap="20px"
                      justifyContent={"start"}
                    >
                      {Product.map((Items, index) => (
                        <Grid
                          item
                          xs="2"
                          sx={{
                            height: "120px",
                            borderRadius: "10px",
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            cursor: "pointer",
                          }}
                        >
                          <CardMedia
                            component="img"
                            alt={`Image Side ${Items.img_name}`}
                            height="100%"
                            image={Items.image}
                            sx={{
                              objectFit: "contain",
                              bgcolor: Items.image && state?.colorCode,
                            }}
                            onClick={() => {
                              HandleMainImage(Items,index);
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Modals----------> */}
          <Box>
            <ResponsiveDialog open={open} close={HandleDialog} />
            <ImageModal
              open={openImageModal}
              close={() => handleImageModal(!openImageModal)}
              Image={selectedModalImage}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default C_product;
