import React from 'react'
import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import Footer from 'app/components/footer/footer'
import NavigationBar from 'app/components/navigation/navigationBar'
import Images from 'app/assets/images'

// const useStyles = makeStyles({
//   firstLetter: {
//     '&:first-letter': {
//       fontSize: '40px',
//       color: 'red',
//       fontWeight: 'bold',
//       float: 'left',
//       lineHeight: '1',
//       marginRight: '4px',
//       // Equivalent to initial-letter: 3;
//       marginBottom: '-12px', // Adjust this value as needed
//     }
//   }
// });

function ReturnPolicy() {
  // const classes = useStyles();
  return (
    <>
      <Box sx={{ mt: 4, mb: 6 }}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8} lg={6}>
            <Typography variant="h3" align="center">
            Return & Refund Policy
            </Typography>
           
            <Typography variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
            *Please note, no returns or exchange on any customized products
            </Typography>
          
            <Typography variant="body1" sx={{ mt: 2 }}>
            Each customer is promised quality of service and premium garments. If there is
            evidence of this not being the case, the customer has 30 days from receiving the
            product to return it for a refund or an exchange. To be eligible for a return,
            your item must be unused and in the same condition that you received it. It
            must also be in the original packaging.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            We do not provide prepaid return shipping labels nor do we cover that
            expense. However, we will cover the cost of shipment for the replacement.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            We cannot guarantee that we will receive your returned item. You should
            consider using a trackable shipping service or purchasing shipping insurance.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            If you receive a defective item we will send you a replacement item free of
            charge without the need for you to return the initial item.
            </Typography>
            
            <Typography variant="h5" sx={{ mt: 2 }}>
            REFUNDS
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            If you haven’t received a refund, contact your bank account or credit card
            company. It may take some time before your refund is officially posted. There
            is often some processing time before a refund is processed. Typically it takes
            about two weeks to show up on your bank statement or credit card. 
            </Typography>
            
            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            If you’ve done all of this and you still have not received your refund, please
            contact us at <a href="mailto:sales@brooklynmadekings.com">sales@brooklynmadekings.com</a>
            </para>
            <Typography variant="body1" sx={{ mt: 2 }}>
            You will be responsible for paying for your shipping costs for returning your
            item. Shipping costs are non-refundable.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            A refund will be issued only after the package has arrived at our warehouse.
            We are not able to issue a refund while the package is in transit.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            It is important that the customer contact us for all inquiries as we will not
            accept unauthorized returns.
            </Typography>

            <Typography variant="h5" sx={{ mt: 2 }}>
            ORDER CANCELATION
            </Typography>

            <br />
            <para variant="body1" sx={{ mt: 2 }}>
            We are able to cancel your order as long as it has not been shipped or in
            production. Any order that is in transit <u>cannot</u> be cancelled.
            </para>

            <Typography variant="body1" sx={{ mt: 2 }} style={{ position: 'relative' }}>
              <span style={{
                fontSize: '63px',
                fontWeight: 'bold',
                lineHeight: '1',
                float: 'left',
                marginRight: '-10px',
                // marginBottom: '-12px', 
                fontFamily: 'Cactus Classical Serif, serif'
              }}>
                I
              </span>
              n order to get a refund you will have to wait for the package to arrive, then 
              ship it back to us and once we receive your package we will issue a refund 
              to the original payment method.
            </Typography>

           
            <Typography variant="h5" sx={{ mt: 2 }}>
            UNCLAIMED ORDERS
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            If a package gets returned to us by the postal service for any reason outside of
            our control the customer has up to 14 days to contact us with an updated
            address to redeliver the package. These circumstances include but not limited
            to: unclaimed packages at the postal office, refusal to accept the delivery of the
            package, wrong delivery address provided by the customer.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            The customer will be responsible for the additional shipping costs.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
            If the customer fails to contact us in the allotted time, the clothing will be
            donated to a local charity.
            </Typography>

          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ReturnPolicy;
